<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.cart") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.cart") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->
		<loader v-if="pageLoading" class="mt-3" />

		<!-- Second Section Of Page -->
		<section
			class="section shared-section-container contact-section"
			id="cart-section"
			v-else
		>
			<div class="my-0">
				<!-- Stepper -->
				<div class="row d-flex justify-content-center px-5">
					<div class="col-lg-6">
						<div class="c-stepper">
							<router-link
								:to="{ name: 'cart' }"
								class="c-stepper__item"
								:disabled="this.$route.query.editPaymentType"
								:event="!this.$route.query.editPaymentType ? 'click' : ''"
							>
								<h3 class="c-stepper__title stepper-active">
									<i class="fas fa-shopping-cart"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.cart") }}</p>
							</router-link>
							<router-link
								:to="{ name: 'cart' }"
								class="c-stepper__item"
								:disabled="this.$route.query.editPaymentType"
								:event="!this.$route.query.editPaymentType ? 'click' : ''"
							>
								<h3 class="c-stepper__title stepper-active">
									<i class="fas fa-shipping-fast"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.shipping") }}</p>
							</router-link>
							<div class="c-stepper__item">
								<h3 class="c-stepper__title stepper-active">
									<i class="fas fa-credit-card"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.payment") }}</p>
							</div>
							<!-- Other steps -->
						</div>
					</div>
				</div>
				<!-- Stepper -->

				<div class="main main-qwe" ref="main-form">
					<div
						class="
							header-payment
							d-flex
							flex-sm-row flex-column
							justify-content-between
							align-items-center
							mb-4
						"
					>
						<p class="blue-text bold text-center m-0">
							{{ $t("cart.payment.payment-option") }}
						</p>
						<p class="total-price bold text-center m-0">
							{{ $t("cart.payment.total") }}:
							{{
								order
									? Intl.NumberFormat().format(order.total_after_shipping)
									: Intl.NumberFormat().format(
											cart.total + shippingCost - couponValue
									  )
							}}
							{{ $t("syp") }}
						</p>
					</div>
					<div class="payment">
						<ul class="payment-list mb-5">
							<li
								class="payment-list-item"
								@click="
									changePaymentType(paymentType.cashOnDelever);
									scrollToForm();
								"
								:class="{
									selected: paymentIndex === paymentType.cashOnDelever,
								}"
							>
								<img
									class="payment-list-item-icon"
									src="/assets/icons/cash-on-delivery.png"
								/>
								<span class="payment-list-item-title">
									{{ $t("cart.payment.cash-delivery") }}
								</span>
							</li>
							<li
								class="payment-list-item"
								@click="
									changePaymentType(paymentType.elecPay);
									scrollToForm();
								"
								:class="{ selected: paymentIndex === paymentType.elecPay }"
							>
								<img
									class="payment-list-item-icon"
									src="/assets/icons/electronic-payment.png"
								/>
								<span class="payment-list-item-title">
									{{ $t("cart.payment.electronic-payment") }}
								</span>
							</li>

							<li
								class="payment-list-item"
								@click="
									changePaymentType(paymentType.bankTrans);
									scrollToForm();
								"
								:class="{ selected: paymentIndex === paymentType.bankTrans }"
							>
								<img
									class="payment-list-item-icon"
									src="/assets/icons/bank-transfer.png"
								/>
								<span class="payment-list-item-title">
									{{ $t("cart.payment.bank-transfer") }}
								</span>
							</li>
						</ul>

						<div class="payment-form">
							<div class="">
								<div
									class="payment-form--type form-1"
									ref="form-1"
									v-if="paymentIndex === paymentType.cashOnDelever"
								></div>
								<div
									class="payment-form--type form-2"
									ref="form-2"
									v-if="paymentIndex === paymentType.elecPay"
								>
									<select
										name="companyName"
										class="payment-form-list mb-4"
										@change="changeValueElec($event)"
										v-model="elexIndex"
									>
										<option value="Syriatel">
											{{ $t("cart.payment.elec.syriatel") }}
										</option>
										<option value="MTN">
											{{ $t("cart.payment.elec.mtn") }}
										</option>
									</select>
									<div>
										<div class="payment-form-code mb-4">
											{{ payment.electronic[elexIndex] }}
										</div>
										<label class="payment-form-label">{{
											$t("component.snackbar.payment.trans-code")
										}}</label>
										<input
											class="payment-form-input"
											type="text"
											v-model="confCode"
										/>
										<input
											class="payment-form-input"
											type="text"
											v-model="confCode2"
										/>
									</div>
								</div>
								<div
									class="payment-form--type form-3"
									ref="form-3"
									v-if="paymentIndex === paymentType.bankTrans"
								>
									<select
										name="companyName"
										class="payment-form-list mb-4"
										@change="changeValueBank($event)"
										v-model="bankIndex"
									>
										<option value="Syria International Islamic Bank">
											{{ $t("cart.payment.bank.islamic") }}
										</option>
										<option value="CHAM BANK">
											{{ $t("cart.payment.bank.cham") }}
										</option>
										<option value="Bemo">
											{{ $t("cart.payment.bank.bemo") }}
										</option>
									</select>
									<div>
										<div class="align-items-center">
											<div class="payment-form-code mb-4">
												{{ payment.bank[bankIndex] }}
											</div>
											<div class="payment-form-qr mb-4" v-if="qr[bankIndex]">
												<img :src="qr[bankIndex]" alt="" />
											</div>
										</div>
										<label class="payment-form-label">{{
											$t("component.snackbar.payment.trans-code")
										}}</label>
										<input
											class="payment-form-input"
											type="text"
											v-model="confCode"
										/>
										<input
											class="payment-form-input"
											type="text"
											v-model="confCode2"
										/>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12" v-if="paymentIndex">
										<button
											@click="placeOrder"
											class="
												btn
												next-btn
												blue-bg
												d-flex
												justify-content-center
												align-items-center
												mb-4
												mt-5
												mx-auto
											"
											type="button"
										>
											<div
												v-if="loading"
												class="spinner-border spinner-border-sm text-light"
												role="status"
											>
												<span class="sr-only">Loading...</span>
											</div>
											<span v-else>
												{{ $t("buttons.done") }}
											</span>
										</button>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<button
											@click="placeOrderChooseLater"
											class="
												btn
												next-btn
												blue-bg
												d-flex
												justify-content-center
												align-items-center
												mb-4
												mt-5
												mx-auto
											"
											type="button"
										>
											<div
												v-if="loadingChooseLater"
												class="spinner-border spinner-border-sm text-light"
												role="status"
											>
												<span class="sr-only">Loading...</span>
											</div>
											<span v-else>
												{{ $t("buttons.choose-later") }}
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
		<snackbar v-model="snackbar">{{ message }}</snackbar>
	</div>
</template>

<script>
import Snackbar from "../../components/Snackbar";
import Loader from "../../components/Loader.vue";
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: false,
			pageLoading: true,
			loadingChooseLater: false,

			snackbar: false,
			message: null,

			paymentType: {
				cashOnDelever: 1,
				elecPay: 2,
				bankTrans: 3,
				BEMO: 4,
			},
			confCode: null,
			confCode2: null,

			qr: {
				"CHAM BANK": "/assets/chbqr.jpg",
				Bemo: "/assets/bemoqr.PNG",
				SyriaIslami: "",
			},

			paymentIndex: null,
			bankIndex: "Syria International Islamic Bank",
			elexIndex: "Syriatel",
			couponValue: 0,

			order: null,
		};
	},
	computed: {
		paymentName() {
			if (this.paymentIndex === this.paymentType.elecPay) {
				return this.elexIndex;
			}
			if (this.paymentIndex === this.paymentType.bankTrans) {
				return this.bankIndex;
			}
			return "cash on delivery";
		},
		...mapState({
			cart: (state) => state.cart.cart,
			user: (state) => state.auth.user,
			payment: (state) => state.payment.payment,
			shippingCost: (state) => state.settings.shippingCost,
		}),
	},
	methods: {
		blockRoute() {
			this.message = this.$t("component.snackbar.block-route.payment");
			this.snackbar = true;
		},

		placeOrder() {
			if (this.loading || this.loadingChooseLater) return;
			if (
				this.paymentIndex &&
				this.paymentIndex !== this.paymentType.cashOnDelever
			) {
				if (!this.confCode) {
					this.message = this.$t("component.snackbar.payment.no-trans-code");
					this.snackbar = true;
					return;
				}
				if (this.confCode !== this.confCode2) {
					this.message = this.$t(
						"component.snackbar.payment.unidentical-trans-code"
					);
					this.snackbar = true;
					return;
				}
			}
			this.loading = true;
			this.isNotValid = false;
			if (this.$route.query.editPaymentType) {
				this.$store
					.dispatch("orders/editPaymentType", {
						id: this.$route.query.editPaymentType,
						paymentType: this.paymentName,
						trans_code: this.confCode,
					})
					.then(() => {
						this.loading = false;
						this.$router.push({ name: "home" });
					})
					.catch((e) => {
						this.loading = false;
						this.message = e.response.data.error;
					});
			} else {
				this.$store
					.dispatch("orders/placeOrder", {
						fullname: this.user.fullname,
						phone: this.user.phone,
						email: this.user.email,
						address: this.$route.query.location,
						residency: this.$route.query.city,
						trans_code: this.confCode,
						payment_type: this.paymentName,
						shipping_cost: this.shippingCost,
						code: this.$route.query.coupon || null,
					})
					.then(() => {
						this.message = this.$t("component.snackbar.order-placed");
						setTimeout(() => {
							this.$store.dispatch("cart/reset");
						}, 3000);
					})
					.catch((e) => {
						this.message = e.response.data.error;
					})
					.finally(() => {
						this.snackbar = true;
						this.loading = false;
						this.$router.push({ name: "home" });
					});
			}
		},
		placeOrderChooseLater() {
			if (this.loading || this.loadingChooseLater) return;
			this.loadingChooseLater = true;
			this.isNotValid = false;
			if (this.$route.query.editPaymentType) {
				this.loadingChooseLater = false;
				this.$router.push({ name: "home" });
			} else {
				this.$store
					.dispatch("orders/placeOrder", {
						fullname: this.user.fullname,
						phone: this.user.phone,
						email: this.user.email,
						address: this.$route.query.location,
						residency: this.$route.query.city,
						shipping_cost: this.shippingCost,
						code: this.$route.query.coupon || null,
					})
					.then(() => {
						this.message = this.$t("component.snackbar.order-placed");
						setTimeout(() => {
							this.$store.dispatch("cart/reset");
						}, 3000);
					})
					.catch((e) => {
						this.message = e.response.data.error;
					})
					.finally(() => {
						this.snackbar = true;
						this.loadingChooseLater = false;
						this.$router.push({ name: "home" });
					});
			}
		},
		scrollToForm() {
			const y =
				document.querySelector(".header-payment").getBoundingClientRect().top +
				window.scrollY -
				100;
			window.scroll({
				top: y,
				behavior: "smooth",
			});
		},
		changeValueElec(e) {
			this.elexIndex = e.target.value;
			this.resetTransCode();
		},
		changeValueBank(e) {
			this.bankIndex = e.target.value;
			this.resetTransCode();
		},
		changePaymentType(index) {
			this.paymentIndex = index;
			this.resetTransCode();
		},
		resetTransCode() {
			this.confCode = null;
			this.confCode2 = null;
		},
	},
	mounted() {
		if (this.$route.query.editPaymentType) {
			this.$store
				.dispatch("orders/fetchById", { id: this.$route.query.editPaymentType })
				.then((res) => {
					this.order = res;
				});
		}
		if (this.$route.query.coupon) {
			Promise.all([
				this.$store.dispatch("cart/getCart"),
				this.$store.dispatch("payment/fetchAll"),
				this.$store
					.dispatch("coupons/checkCoupon", { code: this.$route.query.coupon })
					.then((res) => {
						this.couponValue = Number(res.message);
					})
					.catch(() => {
						alert(this.$t("component.snackbar.cart.invalid-coupon"));
						this.$router.push({ name: "cart" });
					}),
			]).then((e) => {
				this.pageLoading = false;
			});
		} else {
			Promise.all([
				this.$store.dispatch("cart/getCart"),
				this.$store.dispatch("payment/fetchAll"),
			]).then((e) => {
				this.pageLoading = false;
			});
		}
	},
	components: {
		Snackbar,
		Loader,
	},
};
</script>

<style lang="scss" scoped>
// #header .hero-section-overlay {
// 	width: 100%;
// 	height: 80%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	z-index: 0;
// 	background: rgb(18, 40, 74);
// 	background: linear-gradient(
// 		180deg,
// 		rgba(18, 40, 74, 0.7) 30%,
// 		rgba(255, 255, 255, 0) 130%
// 	);
// }
@import "../../sass/variables";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("/assets/cart-bg.jpg");
}

.hero-title-container {
	z-index: 1;
}

#cart-section {
	font-size: clamp(18px, 4vw, 22px);
}
.c-stepper {
	display: flex;
	margin-bottom: 1.1rem;
	padding-left: 0;
}
.c-stepper__item {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
}
[dir="ltr"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
[dir="rtl"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	right: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
.c-stepper__title {
	font-weight: bold;
	font-size: clamp(2.2rem, 4vw, 1.25rem);
	margin-bottom: 0.5rem;
	color: #ccc;
}

@media only screen and (max-width: 500px) {
	.c-stepper__title {
		font-size: 1.3rem !important;
	}
	.c-stepper__desc {
		font-size: 0.9rem !important;
	}
}

@media only screen and (max-width: 400px) {
	.c-stepper__desc {
		font-size: 0.7rem !important;
	}
}

@media only screen and (max-width: 441px) {
	.company {
		margin-top: 13rem !important;
	}
}

@media only screen and (max-width: 454px) and (min-width: 442px) {
	.company {
		margin-top: 10rem !important;
	}
}

@media only screen and (max-width: 560px) and (min-width: 455px) {
	.company {
		margin-top: 6rem !important;
	}
}

.stepper-active {
	color: #183b63 !important;
}
.c-stepper__desc {
	color: grey;
	font-size: clamp(0.85rem, 2vw, 1rem);
	padding-left: var(--spacing);
	padding-right: var(--spacing);
	font-size: 1.3rem;
	font-weight: 500;
	margin: 0;
}
.main {
	margin: auto;
	max-width: 992px;
	width: 100%;
	.payment {
		&-list {
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			align-items: center;
			height: 150px;
			padding: 0;
			&-item {
				width: 24%;
				max-width: 150px;
				height: 100%;
				text-align: center;
				background: #f6f6f6;
				border: 1px solid #b0b0b0;
				border-radius: 15px;
				padding: 0.5em;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				&-icon {
					height: 35%;
					margin-bottom: 0.6em;
				}
				&-title {
					font-size: 0.7em;
					font-weight: 600;
					color: #333;
				}
				&.selected {
					// border: 1px solid #111;
					outline: 2px solid #333;
				}
				@media (max-width: 768px) {
					width: 33%;
				}
			}
		}
		&-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			&-list {
				border: 1px solid #ccc;
				background: none;
				padding: 6px 70px;
				color: #444;
				font-size: 1.3rem;
			}
			&-code {
				padding: 1rem 2rem;
				background: #e5e5e5;
				font-size: 2rem;
				color: black;
				font-weight: 500;
				display: flex;
				justify-content: center;
				align-content: center;
				height: max-content;
				width: max-content;
				margin: auto;
			}
			&-qr {
				height: 120px;
				img {
					height: 100%;
				}
			}
			&-label {
				display: block;
			}
			&-input {
				border: 1px solid #ccc;
				border-radius: 10px;
				display: block;
				margin: 10px auto;
				font-size: 1.3rem;
				padding: 5px;
			}
			&--type {
				// display: none;
				// &.show {
				// 	display: block;
				// }
			}
		}
	}
}
.total-price {
	color: #333;
}

#companyName {
	border: none;
	border-bottom: 2px solid #cccccc;
	background: none;
	color: #333333;
	font-size: 0.9em;
	margin-bottom: 25px;
	height: 45px;
	width: 100%;
}

.details-box {
	padding: 1em;
	background: #f6f6f6;
	position: relative;
	border-radius: 15px;
}

.copy-btn {
	position: absolute;
	right: 0.8em;
	top: 0.4em;
}

.copy-btn button {
	background: none;
	border: none;
	color: #aaa;
	font-size: 0.8em;
}

.payment-box-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dashed-line {
	width: 80%;
	border-bottom: 3px dashed gray;
	height: 0px;
	background: none;
}

span.small-title {
	font-weight: 300;
	color: #aaa;
	font-size: 0.9em;
}

span.content-value {
	font-weight: 500;
	color: #333;
	font-size: 1.1em;
}

.box-footer {
	font-size: 0.6em;
	color: #aaa;
}

.next-btn {
	width: 300px;
	border-radius: 50px;
	color: #fff;
	font-size: 1em;
}
</style>