<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.about-shayah") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.about-shayah") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<!-- Second Section Of Page -->
		<section class="section section-about-us text-start" id="demos">
			<!-- Please Here Set Your Content -->
			<div class="row">
				<div class="col-lg-5 col-md-12">
					<div class="section-our-massage">
						<div class="section-our-massage-image">
							<img src="/assets/about-gray-logo.png" alt="" />
						</div>
						<h3 class="red-title mb-0">{{ $t("about.our-massage") }}</h3>
						<p class="paragraph-bold">
							{{ $t("about.our-massage-text") }}
						</p>
					</div>
				</div>

				<div class="col-lg-7 col-md-12">
					<div class="section-shayah">
						<h3 class="blue-title mb-0">{{ $t("about.trading-Company") }}</h3>
						<p class="section-shayah-paragraph">
							{{ $t("about.trading-Company-text") }}
						</p>
					</div>
				</div>
			</div>

			<div class="row sec-section">
				<div class="col-xl-6 col-lg-7 section-values">
					<div class="section-values-item scientific">
						<div class="section-values-item-header">
							<img
								class="section-values-item-header-image"
								src="assets/icons/atom.png"
								alt=""
							/>
							<h3 class="section-values-item-header-title">
								{{ $t("about.scientific") }}
							</h3>
						</div>
						<div class="section-values-item-content">
							<p class="card-content">
								{{ $t("about.scientific-text") }}
							</p>
						</div>
					</div>

					<div class="section-values-item quality">
						<div class="section-values-item-header">
							<img
								class="section-values-item-header-image"
								src="assets/icons/badge-medal.png"
								alt=""
							/>
							<h3 class="section-values-item-header-title">
								{{ $t("about.honesty") }}
							</h3>
						</div>
						<div class="section-values-item-content">
							<p class="card-content">
								{{ $t("about.honesty-text") }}
							</p>
						</div>
					</div>

					<div class="section-values-item honesty">
						<div class="section-values-item-header">
							<img
								class="section-values-item-header-image"
								src="assets/icons/partnership.png"
								alt=""
							/>
							<h3 class="section-values-item-header-title">
								{{ $t("about.quality") }}
							</h3>
						</div>
						<div class="section-values-item-content">
							<p class="card-content">
								{{ $t("about.quality-text") }}
							</p>
						</div>
					</div>
				</div>

				<div class="col-xl-6 col-lg-5 section-vision">
					<h3 class="red-title">{{ $t("about.our-vision") }}</h3>
					<p class="paragraph-bold">
						{{ $t("about.our-vision-text") }}
					</p>
				</div>
			</div>
			<!-- Please Here Set Your Content -->
		</section>
		<!-- Second Section Of Page -->
	</div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
@import "../../sass/_variables.scss";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("../../../public/assets/about-bg.jpg");
}
.section-about-us {
	padding: 0 7% 50px;
	max-width: 1500px;
	margin: auto;
	@media (max-width: 992px) {
		margin-top: 30px;
	}
}
.section-our-massage {
	padding: 100px 50px;
	padding-left: 0;
	position: relative;
	&-image {
		position: absolute;
		top: 0;
		right: 30%;
		left: -30%;
		bottom: -40px;
		z-index: -1;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	@media (max-width: 992px) {
		padding: 40px 0;
		&-image {
			top: 10%;
			left: 0;
			right: 50%;
			bottom: -100%;
		}
	}
	@media (max-width: 600px) {
		&-image {
			top: 0;
			bottom: -40%;
		}
	}
}
.section-shayah {
	&-paragraph {
		font-size: 1.1rem;
		color: #222;
		line-height: 2rem;
		text-align: justify;
	}
}
.sec-section {
	margin-top: -40px;
	@media (max-width: 992px) {
		margin-top: 20px;
	}
}
.section-values {
	grid-template-areas: "scientific ." "scientific honesty" "quality honesty" "quality .";
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(4, 1fr);
	display: grid;
	gap: 20px;
	@media (max-width: 1200px) {
		height: max-content;
	}
	@media (max-width: 992px) {
		width: max-content;
		margin: auto;
	}
	@media (max-width: 600px) {
		display: block;
	}
	&-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1.8rem;
		width: 300px;
		height: 300px;
		@media (max-width: 1200px) {
			width: 270px;
			height: 270px;
		}
		@media (max-width: 768px) {
			width: 260px;
			height: 260px;
		}

		border-radius: 20px;
		box-shadow: 0 0 15px #0003;
		background: white;
		@media (max-width: 600px) {
			max-width: 350px;
			margin: 30px auto;
		}
		&-header {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			&-image {
				height: 50px;
				margin-bottom: 5px;
			}
			&-title {
				font-size: 1.3rem;
				font-weight: bold;
				margin-bottom: 10px;
			}
		}
		&-content {
			text-align: justify;
			font-size: 1rem;
			line-height: 1.2rem;
			p {
				margin: 0;
			}
		}
	}
	.scientific {
		grid-area: scientific;
	}
	.quality {
		grid-area: quality;
	}
	.honesty {
		grid-area: honesty;
	}
}
.section-vision {
	padding: 100px;
	padding-inline-end: 0;
	@media (max-width: 1200px) {
		padding-inline-end: 20px;
	}
	@media (max-width: 992px) {
		padding: 40px 0;
	}
}
.red-title {
	color: red;
	font-size: 1.2rem;
	text-transform: capitalize;
	font-weight: bold;
	@media (max-width: 992px) {
		font-size: 1rem;
	}
}
.blue-title {
	font-size: 1.8rem;
	font-weight: 500;
	text-transform: capitalize;
	color: #1b3763;
	@media (max-width: 992px) {
		font-size: 1.5rem;
	}
}
.paragraph-bold {
	font-size: 1.7rem;
	font-weight: bold;
	color: black;
	text-align: justify;
	@media (max-width: 992px) {
		font-size: 1.3rem;
	}
}
</style>