import * as types from '../mutations-types'

export default {
    namespaced: true,

    state:{
        news: [],
        newsIndex: {}
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/news')
            .then((response) => {
                commit(types.STORE_NEWS, response.data);
                return response.data;
            })
        },

        fetchRecents({ commit }){
            return window.axios.get('/news/recent')
            .then((response) => {
                commit(types.STORE_NEWS, response.data);
                return response.data;
            })
        },

        fetchById({ commit }, { id }){
            return window.axios.get(`/news/${id}`)
            .then((response) => {
                commit(types.STORE_NEWS_INDEX, response.data);
                return response.data;
            })
        }
    },

    mutations:{
        [types.STORE_NEWS](state, news){
            state.news = news;
        },

        [types.STORE_NEWS_INDEX](state, newsIndex){
            state.newsIndex = newsIndex;
        }
    },

    getters:{
        getNewsById: state => id => state.news.find(c => c.id === id),
    }
}