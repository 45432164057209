import Home from '../pages/Home.vue'
import Products from '../pages/product/Products'
import ProductsSearch from '../pages/product/ProductsSearch'
import ViewProduct from '../pages/product/ViewProduct'
import Articles from '../pages/articles/Articles'
import ViewArticle from '../pages/articles/ViewArticle'
import About from '../pages/about/About'
import ContactUs from '../pages/contact us/ContactUs'
import Cart from '../pages/cart/Cart'
import Shipping from '../pages/cart/Shipping'
import Payment from '../pages/cart/Payment'
import Orders from '../pages/orders/Orders'
import ViewOrder from '../pages/orders/ViewOrder'
import Login from '../pages/signs/Login'
import Signup from '../pages/signs/Signup'
import Page404 from '../pages/Page404'

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/category/:id/products',
        name: 'products',
        component: Products
    },
    {
        path: '/products',
        name: 'products-search',
        component: ProductsSearch
    },
    {
        path: '/view-product/:id',
        name: 'view-product',
        component: ViewProduct
    },
    {
        path: '/articles',
        name: 'articles',
        component: Articles
    },
    {
        path: '/view-article/:id',
        name: 'view-article',
        component: ViewArticle
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart
    },
    {
        path: '/shipping',
        name: 'shipping',
        component: Shipping
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders
    },
    {
        path: '/view-order/:id',
        name: 'view-order',
        component: ViewOrder
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup
    },
    {
        path: '/404',
        name: 'page-404',
        component: Page404
    },
    { 
        path: '*',
        redirect: '/404' 
    },
]