import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        settings: [],
        shippingCost: 0,
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/settings')
                .then((response) => {
                    commit(types.STORE_SETTINGS, response.data)
                    return response.data
                })
        },

    },

    mutations: {
        [types.STORE_SETTINGS](state, settings) {
            state.shippingCost = settings.find(e => e.key === 'shipping_cost').value
            state.settings = settings
        },
    },

    getters: {
        getSocialLinks: state => state.settings.filter(c => c.key === 'facebook' || c.key === 'twitter' || c.key === 'instagram')
    }
}