<template>
    <div>
        <!-- Second Section Of Page -->
        <section id="page-404">
            <div class="section-heading text-center row mb-3 justify-content-center">
                <img class="sayah-logo" src="/assets/logo-dark.png" alt="al shayah logo">
                <h2 class="not-found-title">404 <i class="fas fa-frown"></i></h2>
                <p class="not-found-text">
                    The page is not found. <br>
                    We will redirect you to the Home page in seconds...
                </p>
            </div>
        </section>
        <!-- Second Section Of Page -->
    </div>
</template>

<script>
export default {
    mounted(){
        setTimeout(() => {
            this.$router.push({ name: 'home' });
        }, 5500)
    },
}
</script>

<style lang='scss' scoped>
@import '../sass/_variables.scss';
#page-404{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.sayah-logo{
    width: 300px;
    margin-bottom: 2rem;
}
.not-found{
    &-title{
        font-weight: bold;
        color: $color-primary;
        animation: not-found-pulse .8s ease-in-out alternate infinite;
        @keyframes not-found-pulse {
            from{
                transform: scale(1);
            }
            to{
                transform: scale(1.06);
            }
        }
    }
    &-text{
        font-size: 1.4rem;
        line-height: 3rem;
    }
}
</style>