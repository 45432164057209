import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        cart: {
            products: [],
            total: null
        },
        products: [],

        localCart: [],
        cartId: null,
    },

    actions: {
        getCart({ commit }) {
            return window.axios.get('/cart')
                .then((response) => {
                    commit(types.STORE_CART, response.data);
                    return response.data;
                })
        },
        getCartById({ commit, rootState }, { id }) {
            return window.axios.get('/cart', { params: { cart_id: id } })
                .then((response) => {
                    commit(types.STORE_CART, response.data);
                    return response.data;
                })
        },

        addToLocalCart({ state }, { id, price, quantity }) {
            state.localCart.push({ id, price, quantity })
        },

        async postLocalCart({ dispatch, state }) {
            for (let i = 0; i < state.localCart.length; i++) {
                const e = state.localCart[i];
                await dispatch('addProduct', { item_id: e.id, quantity: e.quantity, price: e.price })
            }
        },

        setId({ state }, { id }) {
            state.cartId = id
        },

        /* eslint-disable */
        addProduct({ commit }, { item_id, quantity, price, id }) {
            return window.axios.post(`/cart/${item_id}`, { quantity, type: 'plain', operation_id: null, product_price: price, cart_id: id })
        },

        editProduct({ commit }, { productId, item_id, quantity, price }) {
            return window.axios.put(`/cart/${productId}`, { item_id, quantity, product_price: price })
        },

        deleteProduct({ commit, state }, { productId, item_id }) {
            return window.axios.delete(`/cart/${productId}`, { data: { item_id } })
        },

        removeProductFromArr({ commit, state }, { id }) {
            console.log(id);
            console.log(state.products);
            state.products.splice(
                state.products.indexOf(state.products.find((c) => c.id === id)),
                1
            );
            console.log(state.products);
        },

        reset({ commit }) {
            commit(types.RESET_CART);
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/cart/${id}/deleteCart`).then(() => {
                commit(types.RESET_CART)
            })
        }
    },

    mutations: {
        [types.STORE_CART](state, cart) {
            state.cart = cart
            state.products = state.cart.products.map(e => {
                return {
                    id: e.id,
                    quantity: e.quantity
                }
            })
        },

        [types.RESET_CART](state) {
            state.cart = {
                products: [],
                total: null
            }
        },
    },

    getters: {

    }
}