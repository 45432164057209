import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        orders: []
    },

    actions: {
        /* eslint-disable */
        placeOrder({ commit }, { fullname, phone, email, address, residency, trans_code, payment_type, shipping_cost, code }) {
            return window.axios.post('/orders/place', {
                fullname, phone, email, address, residency, trans_code, payment_type, shipping_cost, code, postal_code: 1
            });
        },

        fetchAll({ commit }) {
            return window.axios.get('/orders/self')
                .then((response) => {
                    commit(types.STORE_ORDERS, response.data);
                    return response.data;
                })
        },

        fetchById({ commit }, { id }) {
            return window.axios.get(`/orders/self/${id}`)
                .then((response) => {
                    return response.data;
                })
        },

        editPaymentType({ commit, dispatch }, { id, paymentType, trans_code }) {
            return window.axios.put(`/orders/${id}/update`, null, { params: { payment_type: paymentType, trans_code: trans_code } })
                .then(response => {
                    return response
                })
        },
        cancel({ commit }, { id }) {
            return window.axios.put(`/orders/${id}/cancel`).then()
        }
    },

    mutations: {
        [types.STORE_ORDERS](state, orders) {
            state.orders = orders
        },
    },

    getters: {

    }
}