<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.sign-up") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.sign-up") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<!-- Second Section Of Page -->
		<section class="section shared-section-container" id="demos">
			<div class="container-fluid">
				<div class="text-center">
					<form
						@submit.prevent="submit"
						class="login needs-valdate"
						ref="form"
						novalidate
					>
						<img
							class="form-login-img"
							src="assets/logo-dark.png"
							alt="Al Shayah Logo"
						/>

						<div class="row justify-content-center">
							<!-- Full name -->
							<div class="login__field col-6">
								<i class="login__icon fas fa-user-alt"></i>
								<input
									v-model="user.fullname"
									type="text"
									class="login__input"
									:class="{ 'is-not-valid': isNotValid }"
									:placeholder="$t('signs.fullname')"
									required
								/>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6 col-12">
								<!-- Username -->
								<div class="login__field">
									<i class="login__icon fas fa-address-card"></i>
									<input
										v-model="user.username"
										type="text"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.username')"
										required
									/>
								</div>
								<!-- Password -->
								<div class="login__field">
									<i class="login__icon fas fa-lock"></i>
									<input
										v-model="user.password"
										type="password"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.password')"
										required
									/>
								</div>
								<!-- Confirm -->
								<div class="login__field">
									<i class="login__icon fas fa-lock"></i>
									<input
										v-model="user.confirmPassword"
										type="password"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.confirm-password')"
										required
									/>
								</div>
							</div>

							<div class="col-md-6 col-12">
								<!-- Email -->
								<div class="login__field">
									<i class="login__icon far fa-envelope"></i>
									<input
										v-model="user.email"
										type="email"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.email')"
										required
									/>
								</div>
								<!-- phone -->
								<div class="login__field">
									<i class="login__icon fas fa-phone-alt"></i>
									<input
										v-model="user.phone"
										type="text"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.phone')"
										required
									/>
								</div>
								<!-- Address -->
								<div class="login__field">
									<i class="login__icon fas fa-map"></i>
									<input
										v-model="user.address"
										type="text"
										class="login__input"
										:class="{ 'is-not-valid': isNotValid }"
										:placeholder="$t('signs.address')"
										required
									/>
								</div>
							</div>
						</div>
						<div
							class="
								login__field
								d-flex
								justify-content-center
								align-items-center
								text-center
							"
						>
							<button
								type="submit"
								class="button login__submit"
								:class="{ disabled: loading }"
							>
								<span v-if="!loading" class="button__text">
									{{ $t("buttons.sign-up") }}
								</span>
								<div
									v-else
									class="spinner-border spinner-border-sm text-light"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
							</button>
						</div>
					</form>

					<div class="row">
						<p class="form-text">
							{{ $t("signs.have-account") }}
							<router-link :to="{ name: 'login' }" class="signup-link">
								{{ $t("buttons.login") }}
							</router-link>
						</p>
						<h6 class="horizontal-line">
							<span>{{ $t("signs.or") }}</span>
						</h6>
						<p class="form-text">
							{{ $t("signs.sign-with-social") }}
						</p>

						<div
							class="
								social-btns
								d-flex
								justify-content-center
								align-items-center
								text-center
							"
						>
							<button class="form-btn facebook-btn">
								<i class="fab fa-facebook-f"></i>
								{{ $t("buttons.facebook") }}
							</button>
							<button class="form-btn twitter-btn">
								<i class="fab fa-twitter"></i>
								{{ $t("buttons.twitter") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
		<snackbar v-model="snackbar">{{ message }}</snackbar>
	</div>
</template>

<script>
import Snackbar from "../../components/Snackbar";
export default {
	data() {
		return {
			loading: false,
			snackbar: false,
			message: null,
			isNotValid: false,

			user: {
				fullname: null,
				username: null,
				password: null,
				confirmPassword: null,
				email: null,
				phone: null,
				address: null,
			},
		};
	},

	methods: {
		submit() {
			if (this.$refs.form.checkValidity()) {
				this.isNotValid = false;
				this.loading = true;
				this.$store
					.dispatch("auth/signup", {
						fullname: this.user.fullname,
						username: this.user.username,
						password: this.user.password,
						password_confirmation: this.user.confirmPassword,
						email: this.user.email,
						phone: this.user.phone,
						address: this.user.address,
					})
					.then(() => {
						// login after signing up
						this.$store
							.dispatch("auth/login", {
								email: this.user.email,
								password: this.user.password,
							})
							.then(() => {
								this.user.fullname = null;
								this.user.username = null;
								this.user.password = null;
								this.user.confirmPassword = null;
								this.user.email = null;
								this.user.phone = null;
								this.user.address = null;
								this.$store.dispatch("cart/getCart");
								this.$router.push({ name: "home" });
								this.$store.dispatch("cart/postLocalCart").then(() => {
									this.$store.dispatch("cart/getCart");
									this.loading = false;
								});
							})
							.catch((e) => {
								this.message = e.response.data.error;
								this.snackbar = true;
							})
							.finally(() => {
								this.loading = false;
							});
					})
					.catch((e) => {
						this.message = e.response.data.error;
						this.snackbar = true;
					});
			} else {
				this.isNotValid = true;
			}
		},
	},

	components: {
		Snackbar,
	},
};
</script>

<style scoped>
.login {
	width: 100%;
}
.form-login-img {
	width: 200px;
}
@media only screen and (max-width: 1000px) {
	.login__field {
		font-size: 1.6rem;
	}
	.form-text {
		font-size: 1.1rem;
	}
	.form-btn {
		width: 25%;
		font-size: 1.1rem;
	}
}
@media only screen and (max-width: 500px) {
	.form-btn {
		height: 30px;
		font-size: 1.3rem;
	}
	.login__input,
	.login__submit {
		font-size: 1rem;
	}
}

[dir="rtl"] .login__input {
	padding-left: 10px;
	padding-right: 40px;
}
</style>