<template>
	<footer class="site-footer section block bg-dark text-contrast">
		<!-- Curve Wave Shape -->
		<div class="custom-shape-divider-bottom">
			<svg
				class="path-curve-footer"
				id="Layer_1"
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1920.5 335"
			>
				<path
					:class="$route.name === 'home' ? 'cls-11' : 'cls-1'"
					d="M1909.94,0c-12.48,0-25,0-37.42,0Q943.06,0,13.61,0C8.91,0,4.21.32-.5.47V54.06a4.56,4.56,0,0,1,4.5,1c5.18,4.45,11.68,7.32,18.57,9.6,6.22,2.05,12.55,4,19,5.57,32.24,8,65.38,13,98.55,17.68q45.29,6.41,90.75,12c27,3.34,54,6.27,81,9.28,22.7,2.54,45.41,5,68.12,7.4q33.56,3.62,67.13,7.17t67.13,7.17q34.57,3.7,69.12,7.47l67.14,7.18L719.64,153q33.57,3.6,67.14,7.17,34.05,3.66,68.11,7.37c18.37,2,36.73,4,55.11,5.87q29.6,3,59.22,5.71c14.4,1.32,28.82,2.43,43.23,3.69q32.65,2.86,65.31,5.83c20.76,1.85,41.54,3.65,62.29,5.56,20.09,1.86,40.16,3.85,60.23,5.77,24.76,2.36,49.54,4.64,74.28,7.11,24.08,2.4,48.13,5,72.18,7.49,27.74,2.9,55.48,5.71,83.19,8.75,26.37,2.89,52.71,6,79,9.06,29.34,3.42,58.72,6.65,88,10.44,42.9,5.55,85.73,11.46,128.56,17.34,32.27,4.43,64,10.61,94.82,19.57,19.33,5.63,38.45,11.67,56.86,19.06,19.13,7.67,33.24,19.15,41.35,35.18.21.42,1,.67,1.44,1V.47C1916.65.32,1913.29.05,1909.94,0Z"
					transform="translate(0.5 0)"
				/>
			</svg>
		</div>
		<!-- Curve Wave Shape -->

		<div class="container pb-1 pt-0">
			<div class="row mt-4">
				<div class="col-lg-3 col-md-4 col-sm-12">
					<img width="100%" src="/assets/logo.png" alt="Shayah" />
				</div>
			</div>
			<div class="row mt-4 justify-content-center d-flex">
				<div class="col-10 col-lg-3 footer-summary">
					<p class="footer-p">
						{{ $t("footer.about") }}
					</p>
				</div>
				<div class="col-lg-3 px-5">
					<h3 class="footer-section-title text-start">
						{{ $t("footer.links.links") }}
					</h3>
					<hr class="mt-1" />
					<ul class="footer-nav-list d-flex flex-wrap text-start">
						<li class="footer-nav-item">
							<router-link :to="{ name: 'home' }">{{
								$t("footer.links.home")
							}}</router-link>
						</li>
						<li class="footer-nav-item">
							<router-link :to="{ name: 'orders' }">{{
								$t("footer.links.orders")
							}}</router-link>
						</li>
						<li class="footer-nav-item">
							<router-link :to="{ name: 'articles' }">{{
								$t("footer.links.articles")
							}}</router-link>
						</li>
						<li class="footer-nav-item">
							<router-link :to="{ name: 'contact-us' }">{{
								$t("footer.links.contact-us")
							}}</router-link>
						</li>
						<li class="footer-nav-item">
							<router-link :to="{ name: 'about' }">{{
								$t("footer.links.about")
							}}</router-link>
						</li>
					</ul>
				</div>
				<div class="col-lg-2 px-5">
					<h3 class="footer-section-title text-start">
						{{ $t("footer.brands") }}
					</h3>
					<hr class="mt-1" />
					<div class="footer-brand-list d-flex flex-wrap">
						<a href="https://www.profi-car.com/">
							<img width="100%" src="/assets/proficar.png" alt="" />
						</a>
						<a href="https://www.wynns.eu">
							<img width="100%" src="/assets/wynns.png" alt="" />
						</a>
					</div>
				</div>
				<div class="col-lg-3 px-5 text-start">
					<h3 class="footer-section-title">
						{{ $t("footer.follow-us.follow-us") }}
					</h3>
					<hr class="mt-1" />
					<span class="mb-3">{{ $t("footer.follow-us.for-more") }}</span>
					<ul class="follow-icons d-flex justify-content-center mt-2">
						<li class="icon brand-icon">
							<a :href="social.find((c) => c.key === 'facebook')?.value">
								<i class="fab fa-facebook-f"></i>
							</a>
						</li>
						<li class="icon brand-icon">
							<a :href="social.find((c) => c.key === 'instagram')?.value">
								<i class="fab fa-instagram"></i>
							</a>
						</li>
						<li class="icon brand-icon">
							<a :href="social.find((c) => c.key === 'twitter')?.value">
								<i class="fab fa-twitter"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<hr class="mt-2" />
		<div class="copyright">
			<p class="text-muted text-center small-footer-text">
				{{ $t("copy-rights.copy-right") }}© {{ new Date().getFullYear() }}.
				{{ $t("copy-rights.all-rights-reserved") }}
			</p>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			social: "settings/getSocialLinks",
		}),
	},
	mounted() {
		this.$store.dispatch("settings/fetchAll");
	},
};
</script>

<style>
.cls-1 {
	fill: #fbfbfb;
}
.cls-11 {
	fill: #112647;
}
.custom-shape-divider-bottom {
	transform: scaleX(-1);
}
.footer-summary {
	margin-inline-end: auto;
}
.footer-summary > p {
	text-align: justify;
}
@media (max-width: 768px) {
	.footer-summary {
		margin: auto;
	}
}
.copyright {
	background: #173b63;
	padding: 0.5rem 0;
}
.copyright p {
	margin: 0 !important;
}
</style>