<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
					v-if="categories.length"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.product") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						>
						<span class="text-white">{{ $t("header.products") }}</span>
						>
						<router-link
							class="text-white"
							:to="{ name: 'products', params: { id: product.category_id } }"
							>{{
								categories.find((e) => e.id === product.category_id)?.name
							}}</router-link
						>
						> <span class="route-page">{{ product.name }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>

		<loader v-if="loading"></loader>

		<!-- Second Section Of Page -->
		<section
			v-else
			class="section shared-section-container text-start"
			id="demos"
		>
			<div class="my-container">
				<div class="section-heading">
					<div class="product-container mb-3">
						<div class="row">
							<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2">
								<div class="product-image center-item">
									<img :src="mainImage" />
								</div>
								<div class="row mt-3">
									<div
										v-for="image in images"
										:key="image"
										class="col-xxl-3 col-sm-4 col-6"
									>
										<div class="product-other-images">
											<div
												class="product-other-images-item center-item"
												@click="mainImage = image"
											>
												<img :src="image" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2">
								<div>
									<div class="row">
										<div class="col-8">
											<div class="row">
												<h3 class="product-name">{{ product.name }}</h3>
											</div>
											<div class="row w-100 product-meta text-start">
												<span>NO: {{ product.tag }}</span>
												<span class="right-border">
													<i class="fa fa-star checked"></i>
													<i class="fa fa-star checked"></i>
													<i class="fa fa-star checked"></i>
													<i class="fa fa-star checked"></i>
													<i class="fa fa-star checked"></i>
												</span>
												<span class="right-border gray-text">{{
													product.catalog_name
												}}</span>
												<span class="gray-text">{{ product.unit }}</span>
												<h1 class="bold">
													{{ Intl.NumberFormat().format(product.price) }}
													{{ $t("syp") }}
												</h1>
											</div>
										</div>
										<div class="col-4 d-flex align-items-center">
											<img
												class="brand-img float-right"
												:src="getBrandImage()"
											/>
										</div>
									</div>
									<hr class="mt-4 mb-4" />
								</div>
								<!-- description -->
								<div class="row more-options bold">
									<div class="col">{{ $t("product.description") }}</div>
									<div v-html="product.description" class="product-text"></div>
								</div>

								<!-- quantity -->
								<div v-if="!isProductInCart" class="quantity mt-5 mb-4">
									<h5 class="bold">{{ $t("product.quantitiy") }}</h5>
									<div class="spinner-container d-flex">
										<button
											@click="quantity++"
											class="spinner-btn up-btn"
											:class="{ disabled: productLoading }"
										>
											<span class="fas fa-chevron-up"></span>
										</button>
										<input
											class="spinner-input"
											v-model="quantity"
											:disabled="productLoading"
											type="number"
											min="1"
											step="1"
											@keypress.enter="addProduct(product.id)"
										/>
										<button
											@click="quantity > 1 ? quantity-- : null"
											class="spinner-btn down-btn"
											:class="{ disabled: productLoading }"
										>
											<span class="fas fa-chevron-down"></span>
										</button>
									</div>
								</div>

								<!-- add button -->
								<div class="justify-content-center d-flex mb-5">
									<button
										class="add-to-cart-btn"
										:class="{
											disabled:
												productLoading || isProductInCart || !product.active,
										}"
										@click="addProduct(product.id)"
									>
										<div
											v-if="productLoading"
											class="spinner-border spinner-border-sm text-light"
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>

										<span type="button" v-else>
											<template v-if="!product.active">
												{{ $t("buttons.out-of-stock") }}
											</template>
											<template v-else-if="!isProductInCart">
												{{ $t("buttons.add") }}
												<i class="fas spin fa-shopping-cart"></i>
											</template>
											<template v-else>
												{{ $t("buttons.added") }}
												<i class="fas spin fa-check"></i>
											</template>
										</span>
									</button>
								</div>

								<!-- features -->
								<div class="features">
									<h5 class="bold">{{ $t("product.features") }}</h5>
									<div v-html="product.features" class="product-text"></div>
								</div>

								<!-- uses -->
								<div class="row more-options bold mt-5">
									<div class="col">{{ $t("product.uses") }}</div>
									<div v-html="product.uses" class="product-text"></div>
								</div>
							</div>
						</div>
					</div>

					<div class="more-products">
						<div class="title red-text microgramma-font">
							<span>
								{{ $t("product.more-products") }}
							</span>
						</div>
						<div class="row py-5">
							<loader v-if="moreProductLoding" class="my-5"></loader>
							<div
								v-else
								class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
								v-for="product in moreProducts.slice(0, 4)"
								:key="product.id"
							>
								<product-card-small :product="product"></product-card-small>
							</div>
						</div>
						<router-link
							:to="{ name: 'products', params: { id: product.category_id } }"
						>
							<div
								v-if="moreProducts.length > 4"
								class="
									btn
									next-btn
									blue-bg
									d-flex
									justify-content-center
									align-items-center
									mx-auto
								"
							>
								{{ $t("buttons.more-product") }}
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
	</div>
</template>

<script>
import Loader from "../../components/Loader";
import ProductCardSmall from "../../components/ProductCardSmall.vue";
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,
			productLoading: false,
			moreProductLoding: true,
			product: {},
			moreProducts: [],

			images: [],
			mainImage: "",

			quantity: 1,
		};
	},

	computed: {
		isProductInCart() {
			return Boolean(
				this.cart.products.find((c) => c.product.id === this.product.id) ||
					this.localCart.find((c) => c.id === this.product.id)
			);
		},

		...mapState({
			cart: (state) => state.cart.cart,
			localCart: (state) => state.cart.localCart,
			authenticated: (state) => state.auth.authenticated,
			categories: (state) => state.categories.categories,
		}),
	},

	methods: {
		addProduct() {
			this.productLoading = true;
			if (this.authenticated) {
				this.quantity = this.quantity > 0 ? this.quantity : 1;
				this.$store
					.dispatch("cart/addProduct", {
						item_id: this.$route.params.id,
						quantity: this.quantity,
						price: this.product.price,
					})
					.then(() => {
						this.$store.dispatch("cart/getCart").finally(() => {
							this.productLoading = false;
							this.selectedProductId = null;
						});
					})
					.catch((e) => {
						this.message = e.response.data.error;
						this.snackbar = true;
					});
			} else {
				this.$store.dispatch("cart/addToLocalCart", {
					id: this.$route.params.id,
					quantity: this.quantity,
					price: this.product.price,
				});
				setTimeout(() => {
					this.productLoading = false;
					this.selectedProductId = null;
				}, 500);
			}
		},
		fetch() {
			this.loading = true;
			this.$store
				.dispatch("products/fetchById", { productId: this.$route.params.id })
				.then((data) => {
					this.product = data;
					this.loading = false;
					this.images = [this.product.main_image, ...this.product.images];
					this.mainImage = this.product.main_image;
				})
				.finally(() => {
					this.$store
						.dispatch("products/fetchByCategory", {
							categoryId: this.product.category_id,
						})
						.then((res) => {
							this.moreProducts = res.filter(
								(e) => e.id !== this.$route.params.id
							);
							this.moreProductLoding = false;
						});
				});
		},
		getBrandImage() {
			if (this.product.manufacturer.toLocaleLowerCase() === "proficar") {
				return "/assets/proficar.png";
			}
			if (this.product.manufacturer.toLocaleLowerCase() === "wynns") {
				return "/assets/wynns.png";
			}
		},
	},
	watch: {
		$route() {
			this.fetch();
		},
	},
	mounted() {
		this.fetch();
	},

	components: {
		Loader,
		ProductCardSmall,
	},
};
</script>

<style lang='scss'>
@import "../../sass/_variables.scss";
@import "../../../public/css/gallery.css";

.product-image {
	border-radius: 20px;
	background: #eee;
	padding: 1rem;
	img {
		width: 80%;
		max-height: 550px;
		object-fit: contain;
	}
}
.product-other-images {
	border-radius: 20px;
	background: #eee;
	border: 1px solid #ccc;
	padding: 0.4rem;
	cursor: pointer;
	color: black;
	height: 180px;
	@media (min-width: 768px) and (max-width: 1000px) {
		height: 120px;
	}
	&-item {
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.more-products {
	width: 90%;
	margin: auto;
	> .title {
		border-bottom: 2px solid #ccc;
		font-size: 1.8rem;
		text-transform: capitalize;
		span {
			display: inline-block;
			padding: 1rem 0;
			border-bottom: 2px solid #fb0010;
			margin-bottom: -2px;
		}
	}
}

.spinner-btn.disabled {
	background-color: transparent;
}
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("../../../public/assets/product.jpg");
}
[dir="rtl"] {
	.up-btn,
	.down-btn {
		left: 30px;
	}
}
.next-btn {
	width: max-content;
	border-radius: 50px;
	color: #fff;
	font-size: 1rem;
}
</style>
<style >
.more-products .contant {
	color: black;
}
.product-text * {
	font-size: 1.3rem !important;
	text-align: justify;
}
.product-text img {
	max-width: 100%;
}
</style>