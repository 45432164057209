import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: {
            fullname: null
        }
    },

    actions: {
        // eslint-disable-next-line
        signup({ commit }, { fullname, username, password, password_confirmation, email, phone, address }) {
            return window.axios.post('/auth/register', {
                fullname, username, password, password_confirmation, email, phone, address
            })
        },

        login({ commit, dispatch, rootState }, { email, password }) {
            return window.axios.post('/auth/login', {
                email, password, cart_id: rootState.cart.cartId
            })
                .then((response) => {
                    var auth = {
                        fullname: response.data.fullname, token: response.data.access_token, authenticated: true
                    };

                    localStorage.setItem('auth', JSON.stringify(auth));
                    window.axios.defaults.headers.common.Authorization = 'Bearer ' + auth.token;

                    dispatch('getself').then(() => {
                        commit(types.LOGIN)
                    });
                })
        },

        logout({ commit }) {
            localStorage.removeItem('auth');
            delete window.axios.defaults.headers.common.Authorization;
            commit(types.LOGOUT);
        },

        getself({ commit }) {
            return window.axios.get('users/self')
                .then((response) => {
                    commit(types.STORE_USER_DATA, response.data);
                    return response.data;
                })
        }
    },

    mutations: {
        [types.STORE_USER_DATA](state, userData) {
            state.user = userData
        },

        [types.LOGIN](state) {
            state.authenticated = true;
        },

        [types.LOGOUT](state) {
            state.authenticated = false;
            state.user = { fullname: null };
        }
    }
}