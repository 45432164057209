<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.cart") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.cart") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<loader v-if="loading" class="mt-3" />

		<!-- Second Section Of Page -->
		<section
			v-if="!loading && offers.length"
			class="section shared-section-container contact-section"
			id="cart-section"
		>
			<!-- Stepper -->
			<div class="row d-flex justify-content-center px-5">
				<div class="col-lg-6">
					<div class="c-stepper">
						<router-link :to="{ name: 'cart' }" class="c-stepper__item">
							<h3 class="c-stepper__title stepper-active">
								<i class="fas fa-shopping-cart"></i>
							</h3>
							<p class="c-stepper__desc">{{ $t("header.cart") }}</p>
						</router-link>
						<div class="c-stepper__item">
							<h3 class="c-stepper__title">
								<i class="fas fa-shipping-fast"></i>
							</h3>
							<p class="c-stepper__desc">{{ $t("header.shipping") }}</p>
						</div>
						<div class="c-stepper__item">
							<h3 class="c-stepper__title">
								<i class="fas fa-credit-card"></i>
							</h3>
							<p class="c-stepper__desc">{{ $t("header.payment") }}</p>
						</div>
						<!-- Other steps -->
					</div>
				</div>
			</div>
			<!-- Stepper -->

			<div class="my-container my-0">
				<div class="row d-flex justify-content-center px-2 px-md-5">
					<div class="col-lg-7 px-0 px-md-4">
						<ul class="nav nav-tabs products">
							<li class="nav-item">
								<button
									class="nav-link large-title product-details"
									type="button"
								>
									{{ $t("cart.cart.items") }}
								</button>
							</li>
						</ul>

						<ul class="nav products" v-if="cart.products.length > 0">
							<!-- Cart Item -->
							<li
								v-for="(item, itemIndex) in cart.products"
								:key="item.id"
								class="cart-item nav-item mt-4 w-100"
								:class="{
									'cart-item-loading':
										(updateLoading && selectedProductId === item.id) ||
										(deleteLoading && selectedProductToDelete.id === item.id),
								}"
							>
								<div
									class="
										row
										cart-item
										d-flex
										justify-content-between
										align-items-center
									"
								>
									<div class="col-lg-6 col-md-7 col-sm-12 mb-4 text-start">
										<router-link
											:to="{
												name: 'view-product',
												params: { id: item.product.id },
											}"
											class="cart-product d-flex align-items-center"
										>
											<!-- product image -->
											<div class="img">
												<img :src="item.product.main_image" alt="product" />
											</div>

											<!-- prouct detials -->
											<div class="description">
												<span class="category">{{
													item.product.catalog_name
												}}</span>
												<span class="name">{{ item.product.name }}</span>
												<span class="code"
													>{{ $t("cart.cart.product-code") }}:
													{{ item.product.tag }}</span
												>
											</div>
										</router-link>
									</div>

									<!-- quantity -->
									<div
										class="
											col-lg-6 col-md-5 col-sm-12
											mb-3
											d-flex
											justify-content-between
											align-items-center
										"
									>
										<div class="d-flex align-items-center" dir="auto">
											<!-- minus button -->
											<button
												class="btn blue-bg circle-btn"
												:class="{ disabled: updateLoading || deleteLoading }"
												@click="
													products[itemIndex].quantity > 1
														? products[itemIndex].quantity--
														: null
												"
											>
												<i class="fas fa-minus"></i>
											</button>

											<!-- quantity -->
											<span class="mx-3">{{
												products[itemIndex].quantity
											}}</span>

											<!-- plus button -->
											<button
												class="btn blue-bg circle-btn"
												:class="{ disabled: updateLoading || deleteLoading }"
												@click="products[itemIndex].quantity++"
											>
												<i class="fas fa-plus"></i>
											</button>
										</div>
										<!-- price -->
										<div class="price">
											<span>
												{{
													Intl.NumberFormat().format(
														(isOffer(item.product, offers)?.new_price ||
															item.product.price) *
															cart.products[itemIndex].quantity
													)
												}}
												{{ $t("syp") }}
											</span>
										</div>

										<!-- actions -->
										<div class="action text-center">
											<!-- confirm edit button -->
											<button
												@click="
													editProuductQuantity(
														item.id,
														item.product.id,
														products[itemIndex].quantity,
														isOffer(item.product, offers)?.new_price ||
															item.product.price
													)
												"
												:class="{
													disabled: updateLoading || deleteLoading,
													'btn-visible':
														products[itemIndex].quantity !== item.quantity,
													'btn-unvisible':
														products[itemIndex].quantity === item.quantity,
												}"
											>
												<i class="far fa-check-circle fs-4"></i>
											</button>

											<!-- delete button -->
											<button
												@click="selectedProductToDelete = item"
												data-bs-toggle="modal"
												data-bs-target="#deleteModal"
												:class="{ disabled: updateLoading || deleteLoading }"
											>
												<i class="fas fa-trash"></i>
											</button>
										</div>
									</div>
								</div>
							</li>
							<!-- Cart Item -->
						</ul>

						<ul v-else class="nav products px-3 py-2">
							<li class="nav-item">{{ $t("no-data.no-cart-items") }}</li>
						</ul>
					</div>

					<!-- add offers -->
					<div class="col-lg-5 px-0 px-md-4 offers">
						<ul class="nav nav-tabs products">
							<li class="nav-item">
								<button
									class="nav-link large-title product-details"
									type="button"
								>
									{{ $t("cart.cart.summary") }}
								</button>
							</li>
						</ul>

						<ul class="nav nav-tabs products">
							<li
								class="
									summary-item
									d-flex
									justify-content-between
									align-items-center
									mt-3
									mb-3
									w-100
								"
							>
								<span class="summary-title">
									{{ $t("cart.cart.order-cost") }}
								</span>
								<span class="summary-value fix-text-align-mirr">
									{{ Intl.NumberFormat().format(cart.total) || "0" }}
									{{ $t("syp") }}
								</span>
							</li>

							<li
								class="
									summary-item
									d-flex
									justify-content-between
									align-items-center
									my-3
									w-100
								"
								style="position: relative"
							>
								<span class="summary-title">
									{{ $t("cart.cart.enter-code") }}
								</span>
								<span class="summary-value fix-text-align-mirr row"
									><input type="text" v-model="coupon" />
									<button
										v-if="coupon"
										class="coupon-btn btn circle-btn fix-position-mirr"
										@click="checkCopons"
									>
										<div
											class="spinner-border spinner-border-sm"
											style="color: black"
											v-if="couponLoading"
										></div>
										<i
											v-else
											data-v-4e35177b=""
											class="far fa-check-circle fs-4"
										></i></button
								></span>
							</li>

							<li
								class="
									summary-item
									d-flex
									justify-content-between
									align-items-center
									my-3
									w-100
								"
								v-if="couponValue"
							>
								<span class="summary-title">
									{{ $t("cart.cart.coupon") }}
								</span>
								<span class="summary-value fix-text-align-mirr">
									{{ Intl.NumberFormat().format(couponValue) }}
									{{ $t("syp") }}
								</span>
							</li>

							<li
								class="
									summary-item
									d-flex
									justify-content-between
									align-items-center
									my-3
									w-100
								"
							>
								<span class="summary-title">
									{{ $t("cart.cart.shipping-to") }}
								</span>
								<span class="summary-value d-flex cities">
									<select v-model="city" id="city" required>
										<option value selected hidden>
											{{ $t("cart.cart.city") }}
										</option>
										<option v-for="city in cities[$i18n.locale]" :key="city">
											{{ city }}
										</option>
									</select>
									<input
										v-model="location"
										:placeholder="$t('cart.cart.location')"
										name="location"
										id="location"
										required
									/>
								</span>
							</li>

							<li
								class="
									summary-item
									d-flex
									justify-content-between
									align-items-center
									my-3
									w-100
								"
							>
								<span class="summary-title">
									{{ $t("cart.cart.shipping-cost") }}
								</span>
								<span class="summary-value fix-text-align-mirr">
									{{ Intl.NumberFormat().format(shippingCost) }}
									{{ $t("syp") }}
								</span>
							</li>
						</ul>

						<p
							class="
								total-price
								d-flex
								justify-content-between
								align-items-center
								mt-3
								mb-4
								w-100
							"
						>
							<span class="summary-title">
								{{ $t("cart.cart.total-price") }}
							</span>
							<span class="summary-value fix-text-align-mirr">
								{{
									cart.total
										? Intl.NumberFormat().format(
												cart.total + shippingCost - couponValue
										  )
										: "0"
								}}
								{{ $t("syp") }}
							</span>
						</p>
						<router-link
							:to="shippingLink"
							type="button"
							:class="{
								disabled:
									cart.products.length === 0 ||
									updateLoading ||
									deleteLoading ||
									!city ||
									!location,
							}"
							class="
								btn
								total-btn
								blue-bg
								d-flex
								justify-content-center
								align-items-center
								w-90
								mx-auto
								mb-4
							"
						>
							{{ $t("buttons.checkout") }}
						</router-link>

						<router-link
							:to="{
								name: 'orders',
							}"
							type="button"
							:class="{ disabled: updateLoading || deleteLoading }"
							class="
								btn
								order-btn
								d-flex
								justify-content-center
								align-items-center
								w-90
								mx-auto
							"
						>
							{{ $t("buttons.view-previous") }}
						</router-link>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->

		<!-- Third Section Of Page -->
		<section
			v-if="!loading"
			class="section shared-section-container contact-section"
			id="demos"
		>
			<div class="container-fluid my-0">
				<div class="row d-flex justify-content-center px-5">
					<div class="col-lg-12 mx-auto">
						<ul class="nav nav-tabs products" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
								<button
									class="
										nav-link
										active
										microgramma-font
										large-title
										product-details
									"
									id="home-tab"
									data-bs-toggle="tab"
									data-bs-target="#home"
									type="button"
									role="tab"
									aria-controls="home"
									aria-selected="true"
								>
									{{ $t("cart.cart.add-offers") }}
								</button>
							</li>
						</ul>
						<loader v-if="offerLoading" style="margin-top: 100px"></loader>
						<div class="tab-content" id="myTabContent" v-else>
							<div
								class="
									tab-pane
									fade
									show
									active
									d-flex
									flex-wrap
									products-collection
								"
								id="home"
								role="tabpanel"
								aria-labelledby="home-tab"
								v-if="!offers.length"
							>
								<div class="ms-3 my-3">
									{{ $t("no-data.no-items") }}
								</div>
							</div>

							<div class="row py-4">
								<div
									class="col-lg-3 col-md-4 col-sm-6 col-12"
									v-for="(product, i) in offers.filter((e) => e.active)"
									:class="{ 'd-lg-block d-md-none d-sm-block': i == 3 }"
									:key="product.id"
								>
									<product-card
										:product="product"
										style="color: black; transform: scale(0.9)"
									></product-card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Third Section Of Page -->

		<!-- delete modal -->
		<div
			class="modal fade"
			id="deleteModal"
			tabindex="-1"
			aria-labelledby="deleteModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content" v-if="selectedProductToDelete">
					<div class="modal-header">
						<h5 class="modal-title" id="deleteModalLabel">
							{{ $t("cart.cart.confirm-delete") }}
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body text-start">
						{{ $t("cart.cart.delete-message.p1") }}
						{{ selectedProductToDelete.product.name }}
						{{ $t("cart.cart.delete-message.p2") }}
					</div>
					<div class="modal-footer">
						<button
							type="button"
							data-bs-dismiss="modal"
							class="delete-modal-btn"
							@click="
								deleteProduct(
									selectedProductToDelete.id,
									selectedProductToDelete.product.id
								)
							"
						>
							{{ $t("buttons.delete") }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- snackbar -->
		<snackbar v-model="snackbar">{{ message }}</snackbar>
	</div>
</template>

<script>
import Loader from "../../components/Loader";
import Snackbar from "../../components/Snackbar";
import ProductCard from "../../components/ProductCard.vue";
import { mapState } from "vuex";
import { isOffer } from "../../fun";

export default {
	data() {
		return {
			loading: true,
			updateLoading: false,
			deleteLoading: false,
			couponLoading: false,
			offerLoading: false,

			location: null,
			city: "",

			snackbar: false,
			message: null,

			cities: {
				en: [
					"Al-Hasakah",
					"Deir ez-Zor",
					"Raqqa",
					"Aleppo",
					"Idlib",
					"Latakia",
					"Tartus",
					"Hama",
					"Homs",
					"Damascus",
					"Rif Dimashq",
					"As-Suwayda",
					"Daraa",
					"Quneitra",
				],
				ar: [
					"الحسكة",
					"دير الزور",
					"الرقة",
					"حلب",
					"ادلب",
					"اللاذقية",
					"طرطوس",
					"حماة",
					"حمص",
					"دمشق",
					"ريف دمشق",
					"السويداء",
					"درعا",
					"القنيطرة",
				],
			},

			selectedProductId: null,
			selectedProductToDelete: null,

			// products: [],

			coupon: null,
			couponValue: 0,
		};
	},

	watch: {
		authenticated() {
			if (this.authenticated) {
				this.fetch();
			} else {
				this.$router.push({ name: "login" });
			}
			// this.$store.dispatch("offers/fetchAll").then(() => {
			// 	this.offersLoading = false;
			// });
		},
	},

	computed: {
		shippingLink() {
			if (this.couponValue) {
				return {
					name: "shipping",
					query: {
						city: this.city,
						location: this.location,
						coupon: this.coupon,
					},
				};
			}
			return {
				name: "shipping",
				query: { city: this.city, location: this.location },
			};
		},
		...mapState({
			cart: (state) => state.cart.cart,
			products: (state) => state.cart.products,
			authenticated: (state) => state.auth.authenticated,
			shippingCost: (state) => state.settings.shippingCost,
			coupons: (state) => state.coupons.coupons,
			offers: (state) => state.offers.offers,
		}),
	},

	methods: {
		isOffer,
		checkCopons() {
			this.couponLoading = true;
			this.couponValue = 0;
			this.$store
				.dispatch("coupons/checkCoupon", { code: this.coupon })
				.then((response) => {
					if (!response.response) {
						this.message = response.message;
						this.snackbar = true;
					} else {
						this.couponValue = Number(response.message);
					}
				})
				.catch(() => {
					this.message = this.$t("component.snackbar.cart.invalid-coupon");
					this.snackbar = true;
				})
				.finally(() => {
					this.couponLoading = false;
				});
		},
		editProuductQuantity(id, productId, quantity, price) {
			this.updateLoading = true;
			this.selectedProductId = id;
			this.$store
				.dispatch("cart/editProduct", {
					productId,
					item_id: id,
					quantity,
					price,
				})
				.then(() => {
					this.message = this.$t("component.snackbar.product.update");
					this.$store.dispatch("cart/getCart").finally(() => {
						this.snackbar = true;
						this.updateLoading = false;
					});
				})
				.catch((e) => {
					this.message = e.response.data.error;
					this.snackbar = true;
					this.updateLoading = false;
				});
		},

		deleteProduct(id, productId) {
			this.deleteLoading = true;
			this.selectedProductId = id;
			this.$store
				.dispatch("cart/deleteProduct", { productId, item_id: id })
				.then(() => {
					this.message = this.$t("component.snackbar.product.delete");
					this.$store
						.dispatch("cart/getCart")
						.then(() => {
							// remove product form products array
							// this.products.splice(
							// 	this.products.indexOf(this.products.find((c) => c.id === id)),
							// 	1
							// );
							// this.$store.dispatch("cart/removeProductFromArr", { id });
						})
						.finally(() => {
							this.snackbar = true;
							this.deleteLoading = false;
						});
				})
				.catch((e) => {
					this.message = e.response.data.error;
					this.snackbar = true;
					this.deleteLoading = false;
				});
		},

		blockRoute() {
			this.message = this.$t("component.snackbar.block-route.cart");
			this.snackbar = true;
		},

		async fetch() {
			if (this.authenticated) {
				var productsChanged = 0;
				var productsDeleted = 0;
				Promise.all([
					this.$store.dispatch("cart/getCart"),
					this.$store.dispatch("offers/fetchAll"),
				])
					.then(async () => {
						for (let i = 0; i < this.cart.products.length; i++) {
							const p = this.cart.products[i];
							const isOfferProd = this.isOffer(p.product, this.offers);

							if (!p.product.active) {
								++productsDeleted;
								await this.$store.dispatch("cart/deleteProduct", {
									productId: p.product.id,
									item_id: p.id,
								});
								continue;
							}

							if (isOfferProd) {
								if (isOfferProd.new_price !== p.total / p.quantity) {
									++productsChanged;
									await this.$store.dispatch("cart/deleteProduct", {
										productId: p.product.id,
										item_id: p.id,
									});
									await this.$store.dispatch("cart/addProduct", {
										item_id: p.product.id,
										quantity: p.quantity,
										price: isOfferProd.new_price,
									});
								}
							} else {
								const newProduct = await this.$store.dispatch(
									"products/fetchById",
									{ productId: p.product.id }
								);
								if (newProduct.price !== p.total / p.quantity) {
									++productsChanged;
									await this.$store.dispatch("cart/deleteProduct", {
										productId: p.product.id,
										item_id: p.id,
									});
									await this.$store.dispatch("cart/addProduct", {
										item_id: p.product.id,
										quantity: p.quantity,
										price: newProduct.price,
									});
								}
							}
						}

						await this.$store.dispatch("cart/getCart");
						if (productsChanged !== 0) {
							this.message = productsChanged + this.$t("cart.cart.updated");
							this.snackbar = true;
						}
						if (productsDeleted !== 0) {
							this.message = productsDeleted + this.$t("cart.cart.deleted");
							this.snackbar = true;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
	},

	mounted() {
		if ("auth" in localStorage) {
			this.fetch();
		} else {
			this.$router.push({ name: "login" });
		}
	},

	components: {
		Loader,
		Snackbar,
		ProductCard,
	},
};
</script>

<style lang="scss" scoped>
@import "../../sass/_variables.scss";

// [dir="rtl"] {
// 	.cities {
// 		direction: ltr;
// 	}
// }

.coupon-btn {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	i {
		color: black !important;
	}
}

.offers {
	ul {
		padding: 0;
	}
}
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("/assets/cart-bg.jpg");
}

.hero-title-container {
	z-index: 1;
}

#cart-section {
	font-size: clamp(18px, 4vw, 22px);
}

.c-stepper {
	display: flex;
	margin-bottom: 1.1rem;
	padding-left: 0;
}
.c-stepper__item {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
}

[dir="ltr"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
[dir="rtl"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	right: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
.c-stepper__title {
	font-weight: bold;
	font-size: clamp(2.2rem, 4vw, 1.25rem);
	margin-bottom: 0.5rem;
	color: #ccc;
}

@media only screen and (max-width: 500px) {
	.c-stepper__title {
		font-size: 1.3rem !important;
	}
	.c-stepper__desc {
		font-size: 0.9rem !important;
	}
}

@media only screen and (max-width: 400px) {
	.c-stepper__desc {
		font-size: 0.7rem !important;
	}
}

.stepper-active {
	color: #183b63 !important;
}
.c-stepper__desc {
	color: grey;
	font-size: clamp(0.85rem, 2vw, 1rem);
	padding-left: var(--spacing);
	padding-right: var(--spacing);
	font-size: 1.3rem;
	font-weight: 500;
	margin: 0;
}

/* Cart List */
.cart-item {
	border-bottom: 1px solid #d9e2ef;

	&-loading {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			background-color: $color-primary;
			height: 2px;
			animation: cart-item-loading 2s ease-in-out infinite;

			[dir="rtl"] & {
				animation: cart-item-loading-rtl 2s ease-in-out infinite;
			}
		}

		@keyframes cart-item-loading {
			0% {
				width: 0;
				left: 0;
			}
			50% {
				width: 25%;
			}
			100% {
				left: 100%;
				width: 0;
			}
		}

		@keyframes cart-item-loading-rtl {
			0% {
				width: 0;
				right: 0;
			}
			50% {
				width: 25%;
			}
			100% {
				right: 100%;
				width: 0;
			}
		}
	}
}

.cart-product {
	font-size: clamp(1.4em, 4vw, 50%);
	.img {
		margin-right: 0.4em;
		padding: 0.6em;
		width: 100px;
		height: 130px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.description {
	display: flex;
	flex-direction: column;
}
span.category {
	font-size: 0.55em;
	font-weight: 500;
	color: #13274a;
}
span.name {
	font-size: 0.7em;
	font-weight: 600;
	color: #333;
}
span.code {
	color: #ccc;
	font-size: 0.48em;
	font-weight: 400;
}
.input-count input {
	width: 55px;
	border: none;
	background: #fbfbfb;
	color: #cccccc;
	font-size: 0.81em;
	text-align: center;
}

::placeholder {
	color: #cccccc;
	opacity: 1; /* Firefox */
}

#city option,
#location option {
	color: #333333;
}
#city option[value=""],
#location option[value=""] {
	color: #cccccc;
}
#city:invalid,
#location:invalid {
	color: #cccccc;
}

/* Chrome, Safari, Edge, Opera */
.input-count input::-webkit-outer-spin-button,
.input-count input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.input-count input[type="number"] {
	-moz-appearance: textfield;
}

.cart-item .price {
	font-size: 0.9em;
	font-weight: 500;
	color: #333;
}

.action button {
	border: none;
	background: none;
}

.action.text-center {
	font-size: calc(1.5rem + 25%);
}

.action i {
	color: #112647;
	font-size: 0.7em;
}

.action button.disabled i {
	color: #112647a2;
}

.circle-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	color: #fff;
	font-weight: 500;
	width: 25px;
	padding: 0;
	height: 25px;
}

/* Summary List */
.summary-item {
	font-size: 0.85em;
}

.summary-item .summary-title {
	font-weight: 300;
	color: gray;
}
[dir="ltr"] .summary-value {
	text-align: right;
}
[dir="rt"] .summary-value {
	text-align: left;
}
.summary-value {
	flex: 1;
	font-weight: 600 !important;
	color: #333 !important;
}
.summary-value input {
	width: 90%;
}
.summary-item .summary-value input,
.summary-item .summary-value select {
	border: none;
	border-bottom: 2px solid #cccccc;
	background: none;
	font-weight: 500;
	color: #333;
	font-size: 0.8em;
	margin-inline-start: auto;
	display: flex;
	text-align: center;
	height: 45px;
}
.summary-item .summary-value select {
	width: 8em;
	margin: 0 1em;
}

.total-btn {
	border-radius: 25px;
	text-align: center;
	color: #fff;
	height: 3em;
	font-size: 0.6em;
	letter-spacing: 1px;
}

/* Product Offers */
.item-product-offers {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 3em;
	color: #ffffff !important;
	position: relative;
}
.item-product-offers {
	width: 100%;
}

.item-product-offers > h2 {
	color: #333 !important;
	font-weight: 500;
}

.product-img-container {
	position: relative;
}

.badge-offer {
	position: absolute;
	width: 100%;
	height: 100%;
}

img.product-img-badge {
	width: 75%;
	position: absolute;
	right: 0;
}

h6.offer-badge-text.microgramma-font.red-text {
	position: absolute;
	font-size: 0.58rem;
	width: 70%;
	right: 2px;
	top: 10px;
	text-align: center;
}

.offer-percent {
	font-weight: bold;
	font-size: 1.2em;
}

.product-item .product-img {
	width: 75px;
	height: 150px;
}

h2.product-desc {
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 15px;
	color: gray !important;
}

h3.offers-product-brand.red-text {
	font-size: 0.8em;
	margin-bottom: 10px;
	display: flex;
}

.old-price {
	text-decoration: line-through;
	font-size: 14px;
	font-weight: 600;
}

.new-price {
	font-size: 20px;
	color: #019a67;
	font-weight: bold;
	margin-left: 10px;
}

.offers-product-brand::after {
	content: " ــــــــــ";
	margin-left: 30px;
	color: #ccc;
}

.offers-product-brand::before {
	content: " ــــــــــ";
	margin-right: 30px;
	color: #ccc;
}

h2.product-btn.mt-2 {
	font-size: 15px;
}

.order-btn {
	border-color: $color-primary;
	color: $color-primary;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 2rem;
}

///////////////////////////
.btn-visible {
	opacity: 0;
	transform: scale(0);
	visibility: hidden;
	animation: btn-visible 0.3s ease alternate forwards;

	@keyframes btn-visible {
		from {
			opacity: 0;
			transform: scale(0) rotate(-45deg);
			visibility: hidden;
		}
		to {
			opacity: 1;
			transform: scale(1) rotate(0deg);
			visibility: visible;
		}
	}
}
.btn-unvisible {
	opacity: 1;
	transform: scale(1);
	animation: btn-unvisible 0.3s ease alternate forwards;

	@keyframes btn-unvisible {
		from {
			opacity: 1;
			transform: scale(1) rotate(0deg);
			visibility: visible;
		}
		to {
			opacity: 0;
			transform: scale(0) rotate(-45deg);
			visibility: hidden;
		}
	}
}

// modal
.modal {
	&-content {
		border-radius: 30px;
	}
	&-body {
		font-size: 1.25rem;
	}
	&-header,
	&-footer {
		text-transform: uppercase;
		border: none;
	}
	&-header,
	&-body,
	&-footer {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
.delete-modal-btn {
	background-color: $color-primary;
	color: $color-gray-light;
	border: none;
	font-size: 1.1rem;
	border-radius: 50px;
	padding: 4px 30px;
}
.btn-close {
	margin: 0;
	&:focus {
		box-shadow: none;
	}
}
.fa-minus,
.fa-plus {
	font-size: 0.6rem;
}
</style>