<template>
	<div class="my-card my-product-card">
		<router-link
			:to="{ name: 'view-product', params: { id: product.product_details.id } }"
			class="d-block"
		>
			<div class="image">
				<img :src="product.product_details.main_image" alt="" />
			</div>
		</router-link>
		<div class="text">
			<div class="text-title">
				{{ isEnglish? product.product_details.name : product.product_details.name }}
			</div>
			<div class="text-description">
				{{ isEnglish? product.notes :product.notes }}
			</div>
			<div class="text-type">{{ isEnglish? product.product_details.manufacturer : product.product_details.manufacturer }}</div>
		</div>
		<div class="price">
			<div class="price-old">
				{{ Intl.NumberFormat().format(product.product_details.price)
				}}{{ $t("syp") }}
			</div>
			<div class="price-new">
				{{ Intl.NumberFormat().format(product.new_price) }}{{ $t("syp") }}
			</div>
		</div>
		<button
			@click="addProduct(product.product_details.id)"
			class="product-btn mt-2 text-white"
			:class="{
				disabled:
					productLoading ||
					isProductInCart(product.product_details.id) ||
					!product.active,
			}"
		>
			<div
				v-if="
					productLoading && selectedProductId === product.product_details.id
				"
				class="spinner-border spinner-border-sm text-light"
				role="status"
			>
				<span class="sr-only">Loading...</span>
			</div>
			<a type="button" v-else>
				<template v-if="!product.active">
					{{ $t("buttons.out-of-stock") }}
				</template>
				<template v-else-if="!isProductInCart(product.product_details.id)">
					{{ $t("buttons.add") }}
					<i class="fas spin fa-shopping-cart"></i>
				</template>
				<template v-else>
					{{ $t("buttons.added") }}
					<i class="fas spin fa-check"></i>
				</template>
			</a>
		</button>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: ["product"],
	data() {
		return {
			productLoading: false,
		};
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		...mapState({
			cart: (state) => state.cart.cart,
			localCart: (state) => state.cart.localCart,
			cartId: (state) => state.cart.cartId,
			authenticated: (state) => state.auth.authenticated,
		}),
	},
	methods: {
		addProduct(productId) {
			this.productLoading = true;
			this.selectedProductId = productId;
			if (this.authenticated) {
				this.$store
					.dispatch("cart/addProduct", {
						item_id: productId,
						quantity: 1,
						price: this.product.new_price,
					})
					.then(() => {
						this.$store.dispatch("cart/getCart").finally(() => {
							this.productLoading = false;
							this.selectedProductId = null;
						});
					})
					.catch((e) => {
						this.message = e.response.data.error;
						this.snackbar = true;
					});
			} else {
				// this.$store
				// 	.dispatch("cart/addProduct", {
				// 		item_id: productId,
				// 		quantity: 1,
				// 		price: this.product.new_price,
				// 		id: this.cartId,
				// 	})
				// 	.then(() => {
				// 		this.$store
				// 			.dispatch("cart/getCartById", { id: this.cartId })
				// 			.finally(() => {
				// 				this.productLoading = false;
				// 				this.selectedProductId = null;
				// 			});
				// 	})
				// 	.catch((e) => {
				// 		this.message = e.response.data.error;
				// 		this.snackbar = true;
				// 	});
				this.$store.dispatch("cart/addToLocalCart", {
					id: productId,
					quantity: 1,
					price: this.product.new_price,
				});
				setTimeout(() => {
					this.productLoading = false;
					this.selectedProductId = null;
				}, 500);
			}
		},

		isProductInCart(productId) {
			return Boolean(
				this.cart.products.find((c) => c.product.id === productId) ||
					this.localCart.find((c) => c.id === productId)
			);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../sass/variables";
.my-card {
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 600px;
	// overflow: hidden;
	.image {
		width: 100%;
		max-width: 200px;
		height: 240px;
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}
	.text {
		padding: 0 20px;
		text-align: center;
		font-size: 1rem;
		&-title {
			font-size: 1.8rem;
			font-weight: 500;
			line-height: 1.8rem;
			text-transform: uppercase;
			max-height: 5.4rem;
			overflow: hidden;
		}
		&-description {
			font-size: 1rem;
			font-size: 0.9em;
		}
		&-type {
			font-size: 1.2em;
			font-weight: 500;
			margin: 10px auto;
			position: relative;
			text-transform: uppercase;
			width: max-content;
		}
		&-type::after,
		&-type::before {
			content: "";
			display: inline-block;
			border: 1px solid #ccc;
			width: 35px;
			margin: 4px 10px;
			height: 0;
		}
	}
	.price {
		width: 100%;
		display: flex;
		justify-content: center;
		width: max-content;
		&-new {
			font-size: 1.5rem;
			font-weight: 500;
			color: $color-yellow;
			@media (max-width: 1200px) {
				font-size: 1.4rem;
			}
		}
		&-old {
			font-size: 1.3rem;
			text-decoration: line-through;
			margin-inline-end: 10px;
			@media (max-width: 1200px) {
				font-size: 1.1rem;
			}
		}
	}
	.my-btn {
		font-size: 1rem;
		border: 2px solid #ccc;
		padding: 1em 2em;
		border-radius: 2rem;
		&-contant {
			width: max-content;
			display: flex;
			img {
				width: 2rem;
				margin-inline-start: 5px;
			}
		}
	}
}
</style>
<style>
[dir="rtl"] .my-product-card {
	direction: rtl;
}
[dir="rtl"] .price {
	direction: rtl;
}
</style>