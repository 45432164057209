<template>
    <div ref="search" class="search">
        <input v-model="searchText" @keypress.enter="search" class="search-box"  placeholder="search...">
        <button class="search-btn" @click="search"><i class="fas fa-search"></i></button>
        <button class="search-close" @click="isActive = false"><i class="fas fa-times"></i></button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            isActive: false,
            searchText: null
        }
    },

    watch:{
        isActive(){
            if (this.isActive){
                setTimeout(() => {
                    this.$el.classList.add('search-active');
                    document.querySelector('body').style.overflowY = 'hidden';
                }, 100)
            } else {
                setTimeout(() => {
                    this.$el.classList.remove('search-active');
                    document.querySelector('body').style.overflowY = 'scroll';
                }, 100)
            }
        }
    },

    methods:{
        activeSearch(){
            this.isActive = true;
        },

        search(){
            if (this.searchText){
                this.$router.push({ name:'products-search', query:{ search: this.searchText } });
                this.isActive = false;

                setTimeout(() => {
                    this.searchText = null;
                }, 200);
            }
        }
    }
}
</script>

<style lang="scss">
.search{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    visibility: hidden;
    opacity: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgba(#fdfdfd , .85);

    transition: opacity .3s, visibility .3s ;

    &-active{
        visibility: visible;
        opacity: 1;
    }

    &-box{
        height: 45px;
        width: 70%;
        border: none;
        border-bottom: 2px solid #112647;
        background-color: transparent;
        color: #112647;
        font-size: 1rem;
        margin-bottom: 25px;

        @media screen and (min-width: 576px) {
            width: 40%;
        }

        &::placeholder{
            color: lighten(#112647, 10%);
        }
    }

    &-btn, &-close{
        background-color: transparent;
        border: none;
        color: #112647;

        padding: 1rem 1.2rem;
        @media screen and (min-width: 350px) {
            padding: 1rem 1.5rem;
        }
    }

    &-close{
        position: absolute;
        top: 20px;
        right: 2%;

        font-size: 1.6rem;
    }
}
</style>