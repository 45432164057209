export const STORE_RECENT_PRODUCTS = 'STORE_RECENT_PRODUCTS';
export const STORE_PRODUCTS = 'STORE_PRODUCTS';

export const STORE_RECENT_OFFERS = 'STORE_RECENT_OFFERS';
export const STORE_OFFERS = 'STORE_OFFERS';

export const STORE_GATEGORIES = 'STORE_GATEGORIES';

export const STORE_NEWS = 'STORE_NEWS';
export const STORE_NEWS_INDEX = 'STORE_NEWS_INDEX';

export const STORE_USER_DATA = 'STORE_USER_DATA';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const STORE_ORDERS = 'STORE_ORDERS';

export const STORE_CART = 'STORE_CART';
export const RESET_CART = 'RESET_CART';

export const STORE_SLIDER_IMAGES = 'STORE_SLIDER_IMAGES';

export const STORE_PAYMENT = 'STORE_PAYMENT';

export const STORE_SETTINGS = 'STORE_SETTINGS';

export const STORE_BRANCHES = 'STORE_BRANCHES';

export const STORE_COPONS = 'STORE_COPONS';
export const STORE_CHECK_COPON = 'STORE_CHECK_COPON';