<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.article") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						>
						<router-link :to="{ name: 'articles' }" class="text-white">{{
							$t("header.articles")
						}}</router-link>
						> <span class="route-page">{{ newsIndex.title }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<loader v-if="loading" />
		<!-- Second Section Of Page -->
		<section v-else class="text-start" id="demos">
			<div class="my-container">
				<div class="row">
					<div class="col-lg-7 col-md-7 col-sm-12">
						<div class="article d-flex flex-column justify-content-center">
							<div class="article-header">
								<h1 class="article-title">{{ newsIndex.title }}</h1>
								<span class="article-date">
									<i class="far fa-calendar-alt"></i>
									{{ moment(newsIndex.updated_at) }}
								</span>
							</div>

							<div class="article-image mt-1 mb-5">
								<img :src="newsIndex.image" />
							</div>

							<div class="article-content" v-html="newsIndex.content"></div>

							<!-- cool-light-box -->
							<cool-light-box
								:items="newsIndex.images"
								:index="imageIndex"
								:useZoomBar="true"
								:fullScreen="true"
								:dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
								@close="imageIndex = null"
							/>

							<div class="light-box-images-wrapper">
								<div
									class="light-box-image"
									v-for="(image, index) in newsIndex.images"
									:key="index"
									@click="imageIndex = index"
									:style="{ backgroundImage: 'url(' + image + ')' }"
								></div>
							</div>
						</div>
					</div>

					<!-- More Articles -->
					<div class="col-lg-5 col-md-5 col-sm-12 more-article">
						<div class="d-flex justify-content-center align-items-center">
							<div
								class="
									more-article-box
									d-flex
									flex-column
									justify-content-center
								"
							>
								<div class="box-header">
									{{ $t("more-article") }}
								</div>

								<div class="more-article-list">
									<div class="more-article-item">
										<router-link
											v-for="newsInd in news"
											:key="newsInd.id"
											:to="{ name: 'view-article', params: { id: newsInd.id } }"
											class="d-block"
										>
											<span class="item-date">
												<i class="far fa-calendar-alt"></i>
												{{ moment(newsInd.updated_at) }}
											</span>
											<h2 class="item-title">{{ newsInd.title }}</h2>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
	</div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "../../components/Loader";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
	data() {
		return {
			loading: true,
			imageIndex: null,
		};
	},

	computed: {
		...mapState({
			news: (state) => state.news.news,
			newsIndex: (state) => state.news.newsIndex,
		}),
	},

	watch: {
		$route() {
			this.fetchNews();
		},
	},

	methods: {
		moment(date) {
			return window.moment(date).format("DD-MM-YYYY");
		},

		fetchNews() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch("news/fetchById", { id: this.$route.params.id }),
				this.$store.dispatch("news/fetchRecents"),
			]).finally(() => {
				this.removeCurrentNewsfromNews();
				this.loading = false;
			});
		},

		removeCurrentNewsfromNews() {
			this.news.splice(
				this.news.indexOf(
					this.news.find((c) => c.id === Number(this.$route.params.id))
				),
				1
			);
		},
	},

	mounted() {
		this.fetchNews();
	},

	components: {
		Loader,
		CoolLightBox,
	},
};
</script>

<style lang="scss" scoped>
@import "../../sass/_variables.scss";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("../../../public/assets/article.jpg");
}
.article {
	padding-inline-start: 30px;
	@media (max-width: 768px) {
		padding-inline-start: 0;
	}
}
.article-image {
	width: 100%;
	img {
		width: 100%;
		border-radius: 10px;
	}
}
.more-article {
	@media (min-width: 768px) {
		margin-top: 95px;
	}
	&-box {
		width: 85%;
		margin-right: 10%;
		border-radius: 10px;
		border: 1px solid #cccccc;
		.box-header {
			background-color: #0d3e5f;
			width: 100%;
			padding: 10px;
			text-align: center;
			border: none;
			border-radius: 10px 10px 0 0;
			color: #fff;
			font-size: 0.8em;
			margin: 0;
		}
	}

	&-item {
		width: 90%;
		padding: 1rem 10px;
		margin: 0 auto;
		border-bottom: 1px solid #ccc;
		.item-date {
			font-size: 0.9rem;
		}
		.item-title {
			font-size: 1rem;
			color: #333;
			font-weight: normal;
		}
	}
}
.article-title {
	font-size: 2rem;
	font-weight: bold;
}
.article-date {
	font-size: 1rem;
	color: #555;
}

@media only screen and (max-width: 768px) {
	.more-article-box {
		width: 100%;
		margin-right: 0;
	}
}
.more-article-item:last-child {
	border-bottom: none;
}

.more-article-item .item-date {
	&,
	&:link,
	&:visited,
	&:hover {
		color: #6d768b;
	}
}

.light-box-images-wrapper {
	width: 100%;
}
.light-box-image {
	display: inline-block;
	cursor: pointer;
	width: 150px;
	height: 150px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 10px;
	border: 1px solid #ccc;

	&:not(:last-child) {
		[dir="ltr"] & {
			margin-right: 1rem;
		}
		[dir="rtl"] & {
			margin-left: 1rem;
		}
	}
}
</style>
<style>
.article-content * {
	font-size: 1.1rem;
}
@media (max-width: 768px) {
	.article-content * {
		text-align: justify;
	}
}
[dir="rtl"] .article-content * {
	direction: rtl;
}
[dir="ltr"] .article-content * {
	direction: ltr;
}
</style>