import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import ar from './ar'

Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: 'en',
	messages: { en, ar }
});
export default i18n;

export function changeI18nLanguage(){
	var html = document.querySelector('html');
	if (i18n.locale === 'en'){
		this.$i18n.locale = 'ar';
		html.lang = 'ar'; // changing html language
		html.dir = 'rtl'; // and direction
		
		// store current language in local storage
		localStorage.setItem('shayah-app-language', JSON.stringify({ language: 'ar' }));
	}
	else if (i18n.locale === 'ar'){
		i18n.locale = 'en';
		html.lang = 'en'; // changing html language
		html.dir = 'ltr'; // and direction
		
		// store current language in local storage
		localStorage.setItem('shayah-app-language', JSON.stringify({ language: 'en' }));
	}
}

// get stored language
if ('shayah-app-language' in localStorage){
	i18n.locale = JSON.parse(localStorage['shayah-app-language']).language;
	var html = document.querySelector('html');
	html.lang = i18n.locale;
	html.dir = i18n.locale === 'ar' ? 'rtl' : 'ltr';
}