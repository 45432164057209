<template>
	<nav
		id="main-nav"
		class="st-nav navbar main-nav navigation fixed-top"
		ref="nav"
	>
		<div class="container">
			<ul class="st-nav-menu nav navbar-nav">
				<!-- Mobile Navigation -->
				<li class="st-nav-section st-nav-mobile nav-item" ref="navMobile">
					<button class="st-root-link navbar-toggler" type="button">
						<span class="icon-bar"></span> <span class="icon-bar"></span>
						<span class="icon-bar"></span>
					</button>
					<div class="st-popup">
						<div class="st-popup-container">
							<span @click="hideDropdownMenu" class="st-popup-close-button"
								>Close</span
							>
							<div class="st-dropdown-content-group">
								<!-- <h4 class="text-uppercase regular">Pages</h4> -->
								<router-link class="regular text-center" :to="{ name: 'home' }">
									{{ $t("navbar.home") }}
								</router-link>
								<a
									class="regular text-center sh-accordion"
									@click="toggleCategories()"
								>
									{{ $t("navbar.categories") }}
									<i class="arrow down"></i
								></a>
								<div class="sh-panel" ref="categoriesList">
									<!-- for mobile categories -->
									<div class="row" id="categories-mobile">
										<div
											class="
												col-lg-12
												d-flex
												justify-content-center
												flex-wrap
												category-container
											"
										>
											<div
												class="category-item"
												v-for="category in categories"
												:key="category.id"
												@click="hideDropdownMenu()"
											>
												<router-link
													class="
														dropdown-item
														d-flex
														flex-column
														justify-content-center
														align-items-center
													"
													:to="{
														name: 'products',
														params: { id: category.id },
													}"
												>
													<img height="35" :src="category.image" class="mb-2" />
													<span>{{ category.name }}</span>
												</router-link>
											</div>
										</div>
									</div>
								</div>
								<router-link
									:to="{ name: 'orders' }"
									class="regular text-center"
									>{{ $t("navbar.orders") }}</router-link
								>
								<router-link
									:to="{ name: 'articles' }"
									class="regular text-center"
								>
									{{ $t("navbar.articles") }}
								</router-link>
								<a
									class="regular text-center sh-accordion"
									@click="toggleAboutus()"
								>
									{{ $t("navbar.about") }}<i class="arrow down"></i>
									<div class="sh-panel" ref="aboutList">
										<!-- for mobile categories -->
										<div class="" id="about-mobile">
											<ul class="about-us-dropdown-list-mobile">
												<li class="about-us-dropdown-list-mobile-item">
													<router-link :to="{ name: 'about' }">
														<img src="/assets/logo-dark.png" alt="" />
													</router-link>
												</li>
												<li class="about-us-dropdown-list-mobile-item">
													<a href="https://www.profi-car.com/">
														<img src="/assets/proficar.png" alt="" />
													</a>
												</li>
												<li class="about-us-dropdown-list-mobile-item">
													<a href="https://www.wynns.eu">
														<img src="/assets/wynns.png" alt="" />
													</a>
												</li>
											</ul>
										</div>
									</div>
								</a>
								<router-link
									:to="{ name: 'contact-us' }"
									class="regular text-center"
								>
									{{ $t("navbar.contact-us") }}
								</router-link>
							</div>
						</div>
					</div>
				</li>

				<li class="st-nav-section nav-item ms-0 me-auto">
					<router-link :to="{ name: 'home' }" class="navbar-brand border-0">
						<img
							height="40"
							src="/assets/logo-dark.png"
							alt="Shayah"
							class="logo logo-sticky dark-logo d-none"
						/>
						<img
							height="40"
							src="/assets/logo.png"
							alt="Shayah"
							class="logo normal-logo"
						/>
					</router-link>
				</li>

				<li class="st-nav-section st-nav-primary nav-item">
					<router-link
						class="st-root-link nav-link ms-0 me-2"
						:to="{ name: 'home' }"
					>
						{{ $t("navbar.home") }}
					</router-link>
					<a
						class="
							st-root-link
							item-products
							st-has-dropdown
							nav-link
							ms-0
							me-2
						"
						data-dropdown="categories"
					>
						{{ $t("navbar.categories") }}
					</a>
					<router-link
						:to="{ name: 'orders' }"
						class="st-root-link nav-link ms-0 me-2"
					>
						{{ $t("navbar.orders") }}
					</router-link>
					<router-link
						:to="{ name: 'articles' }"
						class="st-root-link nav-link ms-0 me-2"
					>
						{{ $t("navbar.articles") }}
					</router-link>
					<a
						class="st-root-link link-has-dropdown nav-link ms-0 me-2"
						style="position: relative"
						@mouseenter="showAboutUsDropdown = true"
						@mouseleave="showAboutUsDropdown = false"
					>
						{{ $t("navbar.about") }}
						<div
							class="about-us-dropdown"
							:class="{ show: showAboutUsDropdown }"
						>
							<ul class="about-us-dropdown-list">
								<li class="about-us-dropdown-list-item">
									<router-link :to="{ name: 'about' }">
										<img src="/assets/logo-dark.png" alt="" />
									</router-link>
								</li>
								<li class="about-us-dropdown-list-item">
									<a href="https://www.profi-car.com/">
										<img src="/assets/proficar.png" alt="" />
									</a>
								</li>
								<li class="about-us-dropdown-list-item">
									<a href="https://www.wynns.eu">
										<img src="/assets/wynns.png" alt="" />
									</a>
								</li>
							</ul>
						</div>
					</a>
					<router-link
						:to="{ name: 'contact-us' }"
						class="st-root-link nav-link ms-0"
					>
						{{ $t("navbar.contact-us") }}
					</router-link>
				</li>

				<!-- for large screens links -->
				<li class="st-nav-section st-nav-secondary nav-item align-items-center">
					<a
						type="button"
						class="nav-item-icon white-text ms-0 me-3"
						@click="$refs.SearchBox.activeSearch()"
					>
						<i class="fas fa-search"></i>
					</a>

					<router-link
						:to="{ name: 'cart' }"
						class="nav-item-icon position-relative white-text ms-0 me-3"
					>
						<i class="fas fa-shopping-cart"></i>
						<span
							class="badge rounded-pill bg-danger"
							v-if="cartItems > 0"
							:class="{ 'd-none': hiddenum }"
							>{{ cartItems }}</span
						>
					</router-link>

					<router-link
						v-if="!authenticated"
						:to="{ name: 'login' }"
						class="user-btn nav-item-icon ms-0 me-3"
					>
						<i class="fas fa-sign-in-alt"></i>
					</router-link>

					<router-link
						v-if="false"
						:to="{ name: 'login' }"
						class="nav-item-icon white-text ms-0 me-3"
					>
						<i class="fas fa-user"></i>
					</router-link>

					<a
						v-if="authenticated"
						@click="logout"
						type="button"
						class="user-btn nav-item-icon ms-0 me-3"
					>
						<i class="fas fa-sign-out-alt"></i>
					</a>

					<button
						@click="changeI18nLanguage()"
						type="button"
						class="user-btn nav-item-icon p-0 fs-5"
					>
						{{ $t("lang") }}
					</button>
				</li>

				<!-- for mobile links -->
				<li class="st-nav-section st-nav-mobile nav-item align-items-center">
					<a
						type="button"
						class="nav-item-icon white-text ms-0 me-3"
						@click="$refs.SearchBox.activeSearch()"
					>
						<i class="fas fa-search"></i>
					</a>

					<router-link
						:to="{ name: 'cart' }"
						class="nav-item-icon position-relative white-text ms-0 me-3"
					>
						<i class="fas fa-shopping-cart"></i>
						<span class="badge rounded-pill bg-danger" v-if="cartItems > 0">{{
							cartItems
						}}</span>
					</router-link>

					<router-link
						v-if="!authenticated"
						:to="{ name: 'login' }"
						class="user-btn nav-item-icon ms-0 me-3"
					>
						<i class="fas fa-sign-in-alt"></i>
					</router-link>

					<router-link
						v-if="false"
						:to="{ name: 'login' }"
						class="nav-item-icon white-text ms-0 me-3"
					>
						<i class="fas fa-user"></i>
					</router-link>

					<a
						v-if="authenticated"
						@click="logout"
						type="button"
						class="user-btn nav-item-icon ms-0 me-3"
					>
						<i class="fas fa-sign-out-alt"></i>
					</a>

					<button
						@click="changeI18nLanguage()"
						type="button"
						class="user-btn nav-item-icon p-0 fs-5"
					>
						{{ $t("lang") }}
					</button>
				</li>
			</ul>
		</div>

		<!-- Start of Dropdown -->
		<div class="st-dropdown-root">
			<div class="st-dropdown-bg">
				<div class="st-alt-bg"></div>
			</div>
			<div class="st-dropdown-arrow d-none"></div>
			<div class="st-dropdown-container">
				<!-- Start of Dropdown Category -->
				<div class="st-dropdown-section" data-dropdown="categories">
					<div class="st-dropdown-content">
						<div class="st-dropdown-content-group">
							<!-- for large screens categories -->
							<div class="row">
								<div
									class="
										col-lg-12
										d-flex
										justify-content-center
										flex-wrap
										category-container
									"
								>
									<div
										class="category-item"
										v-for="category in categories"
										:key="category.id"
										@click="hideDropdownMenu()"
									>
										<router-link
											class="
												dropdown-item
												d-flex
												flex-column
												justify-content-center
												align-items-center
												mx-2
											"
											:to="{ name: 'products', params: { id: category.id } }"
										>
											<img height="35" :src="category.image" class="mb-2" />
											<span>{{ category.name }}</span>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- End of Dropdown Category -->
				<!-- Start of Dropdown About -->
				<!-- <div class="st-dropdown-section" data-dropdown="about">
                    <div class="st-dropdown-content">
                    <div class="st-dropdown-content-group"></div>
                    </div>
                </div> -->
				<!-- End of Dropdown About -->
			</div>
		</div>
		<!-- End of Dropdown -->

		<search-box ref="SearchBox" />
	</nav>
</template>

<script>
import { mapState } from "vuex";
import { changeI18nLanguage } from "../lang/i18n";
import SearchBox from "../components/SearchBox";
export default {
	data() {
		return {
			showAboutUsDropdown: false,
		};
	},
	computed: {
		cartItems() {
			return this.authenticated ? this.cart.products.length : 0;
		},

		hiddenum() {
			const pages = ["cart", "shipping", "payment"];
			return pages.includes(this.$route.name);
		},

		...mapState({
			authenticated: (state) => state.auth.authenticated,
			categories: (state) => state.categories.categories,
			cart: (state) => state.cart.cart,
		}),
	},

	components: {
		SearchBox,
	},

	methods: {
		logout() {
			this.$store.dispatch("auth/logout");
			this.$store.dispatch("cart/reset");
			this.$router.push({ name: "home" });
		},

		hideDropdownMenu() {
			this.$refs.nav.classList.remove("overlay-active");
			this.$refs.nav.classList.remove("dropdown-active");
			this.$refs.nav.classList.add("no-dropdown-transition");

			this.$refs.navMobile.classList.remove("st-popup-active");
		},
		toggleCategories() {
			this.$refs.categoriesList.classList.toggle("sh-panel");
		},
		toggleAboutus() {
			this.$refs.aboutList.classList.toggle("sh-panel");
		},
		changeI18nLanguage,
	},
	watch: {
		$route() {
			this.hideDropdownMenu();
		},
	},
	mounted() {
		this.$store.dispatch("categories/fetchAll").finally(() => {
			window.initializeNavbar(window.$, window, {});
		});
	},
};
</script>

<style lang='scss'>
.about-us-dropdown {
	position: absolute;
	right: -80%;
	top: 100%;
	cursor: default;
	visibility: hidden;
	&-list {
		padding: 20px !important;
		border-radius: 20px;
		background: #fffd;
		&-item {
			width: 200px;
			height: 50px;
			padding: 0.3rem;
			margin-bottom: 0.3rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				filter: grayscale(0.4);
				cursor: pointer;
				&:hover {
					filter: grayscale(0);
				}
			}
		}
	}
	&.show {
		visibility: visible;
	}
}
.about-us-dropdown {
	padding: 20px;
	cursor: default;
	&-list-mobile {
		&-item {
			width: 200px;
			height: 50px;
			padding: 0.3rem;
			margin: auto;
			margin-bottom: 0.3rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				cursor: pointer;
			}
		}
	}
}
.st-nav a {
	border-bottom: 1px solid transparent;

	&.route-active:not(.nav-item-icon) {
		background-color: transparent;
		color: #173b63;
		border-bottom: 1px solid #173b63;

		@media screen and (max-width: 1200px) {
			border-bottom: none;
		}
	}

	&:hover {
		color: #173b63;
	}
}
.navbar:not(.navbar-sticky) {
	background: linear-gradient(to bottom, #0007, #0000);
}
#main-nav {
	&.navigation.fixed-top {
		.nav-link {
			color: #fff;

			&.route-active:not(.nav-item-icon),
			&:hover {
				border-bottom: 1px solid #fff;

				@media screen and (max-width: 1200px) {
					border-bottom: none;
				}
			}
		}
	}

	&.navigation.navbar-sticky {
		.nav-link {
			color: #6d768b;

			&.route-active:not(.nav-item-icon),
			&:hover {
				color: #173b63;
				border-bottom: 1px solid #173b63;
			}
		}

		.nav-item-icon {
			color: #173b63;
		}
	}

	.user-btn {
		color: white;
		background-color: transparent;
		border: none;
	}
}

.dropdown-toggle::after {
	display: none;
}

.sh-panel {
	background-color: inherit;
}

.badge {
	right: -10px !important;
	top: -5px;
}

.st-dropdown-container {
	transform: translateX(267px);
	[dir="rtl"] & {
		transform: translateX(-200px) !important;
	}
}

.category-item {
	margin-right: 0;
}

#categories-mobile {
	span {
		color: #333;
		padding: 0;
		padding-bottom: 10px;
		line-height: 20px;
	}

	.category-item {
		margin-right: 0;
		@media (max-width: 992px) {
			width: 28% !important;
		}
		@media (max-width: 400px) {
			width: 100% !important;
		}
	}
}
</style>