<template>
	<div class="my-card small-card">
		<div class="contant">
			<router-link :to="{ name: 'view-product', params: { id: product.id } }">
				<div class="image">
					<img :src="product.main_image" alt="" />
				</div>
			</router-link>
			<div class="title my-1">{{ product.name }}</div>
			<div class="price">
				{{ Intl.NumberFormat().format(product.price) }}{{ $t("syp") }}
			</div>
			<div class="rate">
				<h2 class="product-rate mb-0">
					<i class="fa fa-star checked"></i>
					<i class="fa fa-star checked"></i>
					<i class="fa fa-star checked"></i>
					<i class="fa fa-star checked"></i>
					<i class="fa fa-star checked"></i>
				</h2>
			</div>
			<button
				@click="addProduct(product.id)"
				class="product-btn btn mt-2 text-white"
				:class="{
					disabled:
						productLoading || isProductInCart(product.id) || !product.active,
				}"
			>
				<div
					v-if="productLoading && selectedProductId === product.id"
					class="spinner-border spinner-border-sm text-light"
					role="status"
				>
					<span class="sr-only">Loading...</span>
				</div>
				<a type="button" v-else>
					<template v-if="!product.active">
						{{ $t("buttons.out-of-stock") }}
					</template>
					<template v-else-if="!isProductInCart(product.id)">
						{{ $t("buttons.add") }}
						<i class="fas spin fa-shopping-cart"></i>
					</template>
					<template v-else>
						{{ $t("buttons.added") }}
						<i class="fas spin fa-check"></i>
					</template>
				</a>
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: ["product"],
	data() {
		return {
			productLoading: false,
		};
	},
	computed: {
		...mapState({
			cart: (state) => state.cart.cart,
			localCart: (state) => state.cart.localCart,
			authenticated: (state) => state.auth.authenticated,
		}),
	},
	methods: {
		addProduct(productId) {
			this.productLoading = true;
			this.selectedProductId = productId;
			if (this.authenticated) {
				this.$store
					.dispatch("cart/addProduct", {
						item_id: productId,
						quantity: 1,
						price: this.product.price,
					})
					.then(() => {
						this.$store.dispatch("cart/getCart").finally(() => {
							this.productLoading = false;
							this.selectedProductId = null;
						});
					})
					.catch((e) => {
						this.message = e.response.data.error;
						this.snackbar = true;
					});
			} else {
				this.$store.dispatch("cart/addToLocalCart", {
					id: productId,
					quantity: 1,
					price: this.product.price,
				});
				setTimeout(() => {
					this.productLoading = false;
					this.selectedProductId = null;
				}, 500);
			}
		},

		isProductInCart(productId) {
			return Boolean(
				this.cart.products.find((c) => c.product.id === productId) ||
					this.localCart.find((c) => c.id === productId)
			);
		},
	},
};
</script>

<style scoped lang="scss">
.my-card {
	.btn {
		font-size: 0.7rem;
		padding: 0.7rem 1rem;
		@media (max-width: 576px) {
			font-size: 0.6rem;
			padding: 0.4rem 0.6rem;
		}
	}
}
.contant {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	.image {
		width: 100%;
		height: 200px;
		@media (max-width: 992px) {
			height: 130px;
		}
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}
	.title {
		font-size: 1.1rem;
		line-height: 1.1rem;
		text-align: center;
		max-height: 3.3rem;
		overflow: hidden;
		@media (max-width: 992px) {
			font-size: 0.9rem;
			line-height: 0.9rem;
			max-height: 2.7rem;
		}
	}
	.price {
		font-size: 1.2rem;
		font-weight: 500;
		width: max-content;
		@media (max-width: 992px) {
			font-size: 1rem;
		}
	}
	.rate {
		width: max-content;
		* {
			font-size: 1rem;
			@media (max-width: 992px) {
				font-size: 0.6rem;
			}
		}
	}
}
</style>
<style >
[dir="rtl"] .small-card .title {
	direction: rtl;
}
</style>