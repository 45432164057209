import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        payment: {
            bank: [],
            electronic: []
        }
    },

    actions: {
        fetchAll({ commit }) {
            window.axios.get('/orders/pymenttype', { params: { type: "SIB" } })
                .then((response) => {
                    commit(types.STORE_PAYMENT, { data: response.data, type: 'bank' });
                    return response.data;
                })
            window.axios.get('/orders/pymenttype', { params: { type: "MTN" } })
                .then((response) => {
                    commit(types.STORE_PAYMENT, { data: response.data, type: 'electronic' });
                    return response.data;
                })
        },
    },

    mutations: {
        [types.STORE_PAYMENT](state, payment, type) {
            state.payment[payment.type] = payment.data
        },
    },

    getters: {

    }
}