<template>
	<loader
		v-if="loading && !showPage"
		light
		class="mb-0"
		style="height: 100vh; background: #112647"
	/>
	<div
		v-else
		v-show="showPage"
		class="home-bg overflow-hidden full-page-container"
	>
		<header id="header" class="header header-home section text-contrast cover">
			<div
				class="
					hero-section-overlay
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text">{{ $t("home.more-power") }}</h1>
					<h3 class="microgramma-font">{{ $t("home.quality") }}</h3>
				</div>
			</div>
			<div class="super-image">
				<div
					id="carouselExampleControls"
					class="carousel slide super-image"
					data-bs-ride="carousel"
					data-bs-interval="3000"
				>
					<div class="carousel-inner super-image">
						<div
							class="carousel-item super-image"
							v-for="silderImage in silderImages"
							:key="silderImage.id"
						>
							<img :src="silderImage.link" class="d-block w-100" />
						</div>
					</div>
					<button
						class="carousel-control-prev"
						type="button"
						data-bs-target="#carouselExampleControls"
						data-bs-slide="prev"
					>
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button
						class="carousel-control-next"
						type="button"
						data-bs-target="#carouselExampleControls"
						data-bs-slide="next"
					>
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</div>
			</div>
		</header>

		<!-- First Section Of Page -->
		<!-- Sec 	ond Section Of Page -->
		<section class="section section-offers special-offer-section" id="demos">
			<div class="star-bg"><img src="/assets/background.png" alt="" /></div>
			<div class="section-offers-outter">
				<div class="section-offers-outter-logo m-auto mb-3">
					<img
						class="special-offers-img"
						src="assets/offers.png"
						alt="special offer"
					/>
				</div>

				<div class="section-offers-outter-slider">
					<div class="section-offers-outter-slider-container">
						<!-- loader -->
						<loader light v-if="loading" style="margin-top: 4rem" />

						<!-- slider items (recentProducts) -->

						<carousel
							class="owl-carousel-offer"
							:responsive="{ 0: { items: 1 }, 1000: { items: 3 } }"
							:margin="10"
							:loop="true"
							@changed="changed1"
							:autoplay="true"
							:dots="false"
							:autoplayHoverPause="true"
							:navText="[
								'<i class=\'fa fa-chevron-left\'></i>',
								'<i class=\'fa fa-chevron-right\'></i>',
							]"
							dir="ltr"
						>
							<!-- Start Of Slider Item -->
							<div
								v-for="product in offers.filter((e) => e.active)"
								:key="product.id"
								class="item"
							>
								<span>
									<product-card :product="product"></product-card>
								</span>
							</div>
							<!-- End Of Slider Item -->
						</carousel>
						<!-- End Of Slider -->
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->

		<!-- Third Section Of Page -->
		<!-- OUR BRANDS SECTION -->
		<section class="section brands" id="demos">
			<div class="section-brands">
				<h2 class="section-brands-title microgramma-font text-center mb-4">
					{{ $t("home.titles.our") }}
					<span class="red-text">{{ $t("home.titles.brands") }}</span>
				</h2>
				<div
					class="
						section-brands-image
						d-flex
						justify-content-center
						align-items-center
					"
				>
					<div class="col-6 col-lg-3 section-brands-image-item px-2">
						<a href="https://www.profi-car.com/" target="_blank">
							<img src="/assets/proficar.png" alt="proficar" />
						</a>
					</div>
					<div class="col-6 col-lg-3 section-brands-image-item px-2">
						<a href="https://www.wynns.eu" target="_blank">
							<img src="/assets/wynns.png" alt="wynns" />
						</a>
					</div>
				</div>
			</div>
		</section>
		<!-- Third Section Of Page -->
		<!-- OUR BRANDS SECTION -->

		<!-- Fourth Section Of Page -->
		<!-- PRODUCTS TAB SECTION -->
		<section class="section" id="demos" dir="ltr">
			<!-- loader -->
			<loader light v-if="loading" />

			<!-- products -->
			<div class="section-products" v-else>
				<!-- tabs -->
				<ul class="nav nav-tabs" id="myTab" role="tablist" dir="auto">
					<li class="nav-item" role="presentation">
						<button
							class="nav-link active"
							id="home-tab"
							data-bs-toggle="tab"
							data-bs-target="#home"
							type="button"
							role="tab"
							aria-controls="home"
							aria-selected="true"
							@click="
								featuredProducts = false;
								lastProducts = false;
								carouselLoadingFun();
							"
						>
							{{ $t("tabs.all") }}
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button
							class="nav-link"
							id="profile-tab"
							data-bs-toggle="tab"
							data-bs-target="#profile"
							type="button"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
							@click="
								featuredProducts = true;
								lastProducts = false;
								carouselLoadingFun();
							"
						>
							{{ $t("tabs.most-popular") }}
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button
							class="nav-link"
							id="contact-tab"
							data-bs-toggle="tab"
							data-bs-target="#contact"
							type="button"
							role="tab"
							aria-controls="contact"
							aria-selected="false"
							@click="
								featuredProducts = false;
								lastProducts = true;
								carouselLoadingFun();
							"
						>
							{{ $t("tabs.latest-popular") }}
						</button>
					</li>
				</ul>

				<!-- tabs content -->
				<div class="section-products-slider" id="myTabContent">
					<!-- content tab 1-->
					<loader v-if="carouselLoading" class="my-5"></loader>
					<carousel
						v-else-if="productsArr.length"
						class="owl-carousel-product"
						:responsive="{ 0: { items: 3 }, 1200: { items: 5 } }"
						:margin="10"
						@changed="changed2"
						:autoplayHoverPause="true"
						:dots="false"
						:navText="[
							'<i class=\'fa fa-chevron-left\'></i>',
							'<i class=\'fa fa-chevron-right\'></i>',
						]"
						:autoplay="true"
					>
						<!-- Start Of Slider (products) -->
						<!-- Start Of Slider Item -->
						<div v-for="product in productsArr" :key="product.id" class="item">
							<!-- <router-link
											:to="{
												name: 'view-product',
												params: { id: product.id },
											}"
											class="item item-product"
										>
											<img
												class="product-img"
												:src="product.main_image"
												alt="product"
											/>
											<h2 class="product-name">{{ product.name }}</h2>
											<h2 class="product-price">{{ product.price }}SP</h2>
											<h2 class="product-rate">
												<i class="fa fa-star checked"></i>
												<i class="fa fa-star checked"></i>
												<i class="fa fa-star checked"></i>
												<i class="far fa-star checked"></i>
												<i class="far fa-star checked"></i>
											</h2>
										</router-link>

										<button
											@click="addProduct(product.id)"
											class="product-btn btn mt-2 text-white"
											:class="{
												disabled: productLoading || isProductInCart(product.id),
											}"
										>
											<div
												v-if="
													productLoading && selectedProductId === product.id
												"
												class="spinner-border spinner-border-sm text-light"
												role="status"
											>
												<span class="sr-only">Loading...</span>
											</div>

											<a type="button" v-else>
												<template v-if="!isProductInCart(product.id)">
													{{ $t("buttons.add") }}
													<i class="fas spin fa-shopping-cart"></i>
												</template>
												<template v-else>
													{{ $t("buttons.added") }}
													<i class="fas spin fa-check"></i>
												</template>
											</a>
										</button> -->
							<product-card-small
								:product="product"
								class="section-products-slider-item"
							></product-card-small>
						</div>
						<!-- End Of Slider Item -->

						<!-- End Of Slider -->
					</carousel>
					<div class="no-items" v-if="!productsArr.length && !carouselLoading">
						{{ $t("no-data.no-items") }}
					</div>
				</div>
			</div>
		</section>
		<!-- PRODUCTS TAB SECTION -->
		<!-- Fourth Section Of Page -->

		<snackbar v-model="snackbar">{{ message }}</snackbar>
	</div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "../components/Loader";
import Snackbar from "../components/Snackbar";
import ProductCard from "../components/ProductCard.vue";
import ProductCardSmall from "../components/ProductCardSmall.vue";
import carousel from "vue-owl-carousel";
import moment from "moment";

export default {
	data() {
		return {
			loading: true,
			productLoading: false,
			selectedProductId: null,
			snackbar: false,
			message: null,

			featuredProducts: false,
			lastProducts: false,
			carouselLoading: false,

			showPage: false,

			productsTypes: {
				all: 0,
				popular: 1,
				latest: 2,
			},
			productsToShow: 0,
		};
	},

	computed: {
		productsArr() {
			if (this.featuredProducts) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				return this.products.filter((e) => e.is_featured);
			}
			if (this.lastProducts) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				return this.products
					.map((e) => e)
					.sort((a, b) => {
						if (moment(a.created_at).isAfter(moment(b.created_at))) return -1;
						if (moment(a.created_at).isBefore(moment(b.created_at))) return 1;
						return 0;
					})
					.slice(0, 10);
			}
			return this.products;
		},
		...mapState({
			authenticated: (state) => state.auth.authenticated,
			products: (state) => state.products.products,
			recentProducts: (state) => state.products.recentProducts,
			cart: (state) => state.cart.cart,
			silderImages: (state) => state.silderImages.silderImages,
			offers: (state) => state.offers.offers,
		}),
	},

	methods: {
		carouselLoadingFun() {
			this.carouselLoading = true;
			setTimeout(() => {
				this.carouselLoading = false;
				this.changed2();
			}, 1000);
		},
		// addProduct(productId) {
		// 	if (this.authenticated) {
		// 		this.productLoading = true;
		// 		this.selectedProductId = productId;
		// 		this.$store
		// 			.dispatch("cart/addProduct", { item_id: productId, quantity: 1 })
		// 			.then(() => {
		// 				this.$store.dispatch("cart/getCart").finally(() => {
		// 					this.productLoading = false;
		// 					this.selectedProductId = null;
		// 				});
		// 			})
		// 			.catch((e) => {
		// 				this.message = e.response.data.error;
		// 				this.snackbar = true;
		// 			});
		// 	} else {
		// 		this.$router.push({ name: "login" });
		// 	}
		// },

		isProductInCart(productId) {
			return Boolean(
				this.cart.products.find((c) => c.product.id === productId)
			);
		},

		setSlickCarousels() {
			// var slickOffersCarouselClickedHanlder = () =>
			// 	(slickOffersCarouselClicked = true);
			// var slickOffersCarouselClicked = false;
			// setInterval(() => {
			// 	if (slickOffersCarouselClicked) {
			// 		return;
			// 	}
			// 	$(".slick-offers .slick-next").off(
			// 		"click",
			// 		slickOffersCarouselClickedHanlder
			// 	);
			// 	$(".slick-offers .slick-next").click();
			// 	$(".slick-offers .slick-next").on(
			// 		"click",
			// 		slickOffersCarouselClickedHanlder
			// 	);
			// }, 3000);
			// $(".slick-offers")
			// 	.on("init", () => {
			// 		$('.slick-offers .slick-slide[data-slick-index="-1"]').addClass(
			// 			"lt3"
			// 		);
			// 		$('.slick-offers .slick-slide[data-slick-index="1"]').addClass("gt3");
			// 		$(".slick-offers .slick-next").click();
			// 		$(".slick-offers .slick-prev").click();
			// 		$(".slick-offers .slick-next").on(
			// 			"click",
			// 			slickOffersCarouselClickedHanlder
			// 		);
			// 		$(".slick-offers .slick-prev").on(
			// 			"click",
			// 			slickOffersCarouselClickedHanlder
			// 		);
			// 	})
			// 	.slick({
			// 		centerMode: true,
			// 		rtl: this.$i18n.locale === "ar",
			// 		centerPadding: 0,
			// 		slidesToShow: 3,
			// 		nextArrow:
			// 			'<a class="slick-next btn prev grey-btn border-width-0" id="prev"><i class="fas fa-chevron-right"></i></a>',
			// 		prevArrow:
			// 			'<a class="slick-prev btn prev grey-btn border-width-0" id="prev"><i class="fas fa-chevron-left"></i></a>',
			// 		responsive: [
			// 			{
			// 				breakpoint: 600,
			// 				settings: {
			// 					slidesToShow: 1,
			// 				},
			// 			},
			// 		],
			// 	})
			// 	.on("beforeChange", (event, slick, current, next) => {
			// 		$(".slick-offers .slick-slide.gt3").removeClass("gt3");
			// 		$(".slick-offers .slick-slide.lt3").removeClass("lt3");
			// 		$(
			// 			`.slick-offers .slick-slide[data-slick-index="${current - 1}"]`
			// 		).addClass("lt3");
			// 		$(
			// 			`.slick-offers .slick-slide[data-slick-index="${current + 1}"]`
			// 		).addClass("gt3");
			// 	});
			// var slickCarouselClickedHandler = () => (slickCarouselClicked = true);
			// var slickCarouselClicked = false;
			// const slickChildrenCount = $(".slick").first().children().length;
			// setInterval(() => {
			// 	if (slickCarouselClicked) {
			// 		return;
			// 	}
			// 	$(".slick .slick-next").off("click", slickCarouselClickedHandler);
			// 	$(".slick .slick-next").click();
			// 	$(".slick .slick-next").on("click", slickCarouselClickedHandler);
			// }, 3000);
			// $(".slick")
			// 	.on("init", () => {
			// 		$('.slick .slick-slide[data-slick-index="-2"]').addClass("lt2");
			// 		$('.slick .slick-slide[data-slick-index="-1"]').addClass("lt1");
			// 		$('.slick .slick-slide[data-slick-index="1"]').addClass("gt1");
			// 		$('.slick .slick-slide[data-slick-index="2"]').addClass("gt2");
			// 		$(".slick .slick-next").on("click", slickCarouselClickedHandler);
			// 		$(".slick .slick-prev").on("click", slickCarouselClickedHandler);
			// 	})
			// 	.slick({
			// 		centerMode: true,
			// 		centerPadding: 0,
			// 		rtl: this.$i18n.locale === "ar",
			// 		slidesToShow: 5,
			// 		nextArrow:
			// 			'<a class="slick-next btn prev grey-btn border-width-0" id="prev"><i class="fas fa-chevron-right"></i></a>',
			// 		prevArrow:
			// 			'<a class="slick-prev btn prev grey-btn border-width-0" id="prev"><i class="fas fa-chevron-left"></i></a>',
			// 		responsive: [
			// 			{
			// 				breakpoint: 600,
			// 				settings: {
			// 					slidesToShow: 3,
			// 				},
			// 			},
			// 		],
			// 	})
			// 	.on("beforeChange", (event, slick, current, next) => {
			// 		$(".slick .slick-slide.gt2").removeClass("gt2");
			// 		$(".slick .slick-slide.gt1").removeClass("gt1");
			// 		$(".slick .slick-slide.lt1").removeClass("lt1");
			// 		$(".slick .slick-slide.lt2").removeClass("lt2");
			// 		var lt2 = next - 2;
			// 		var lt1 = next - 1;
			// 		var gt1 = next + 1;
			// 		var gt2 = next + 2;
			// 		if (current === 0 && next === slickChildrenCount - 1) {
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current - 3}"]`
			// 			).addClass("lt2");
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current - 2}"]`
			// 			).addClass("lt1");
			// 			$(`.slick .slick-slide[data-slick-index="${current}"]`).addClass(
			// 				"gt1"
			// 			);
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current + 1}"]`
			// 			).addClass("gt2");
			// 		}
			// 		if (current === slickChildrenCount - 1 && next === 0) {
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current - 1}"]`
			// 			).addClass("lt2");
			// 			$(`.slick .slick-slide[data-slick-index="${current}"]`).addClass(
			// 				"lt1"
			// 			);
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current + 2}"]`
			// 			).addClass("gt1");
			// 			$(
			// 				`.slick .slick-slide[data-slick-index="${current + 3}"]`
			// 			).addClass("gt2");
			// 		}
			// 		$(`.slick .slick-slide[data-slick-index="${lt2}"]`).addClass("lt2");
			// 		$(`.slick .slick-slide[data-slick-index="${lt1}"]`).addClass("lt1");
			// 		$(`.slick .slick-slide[data-slick-index="${gt1}"]`).addClass("gt1");
			// 		$(`.slick .slick-slide[data-slick-index="${gt2}"]`).addClass("gt2");
			// 	});
			// $(document).ready(function () {
			// 	$("#owl-demo").owlCarousel({
			// 		navigation: false,
			// 		slideSpeed: 300,
			// 		paginationSpeed: 400,
			// 		singleItem: true,
			// 		autoPlay: true,
			// 		autoHeight: false,
			// 		rewindNav: true,
			// 		rewindSpeed: 1000,
			// 	});
			// });
			// // Custom Navigation Events
			// $("#next").click(function () {
			// 	$("#owl-demo").trigger("owl.next");
			// });
			// $("#prev").click(function () {
			// 	$("#owl-demo").trigger("owl.prev");
			// });
			// var acc = document.getElementsByClassName("sh-accordion");
			// var i;
			// for (i = 0; i < acc.length; i++) {
			// 	acc[i].addEventListener("click", function () {
			// 		/* Toggle between adding and removing the "active" class,
			// 		to highlight the button that controls the panel */
			// 		this.classList.toggle("sh-active");
			// 		/* Toggle between hiding and showing the active panel */
			// 		var panel = this.nextElementSibling;
			// 		if (panel.style.display === "block") {
			// 			panel.style.display = "none";
			// 		} else {
			// 			panel.style.display = "block";
			// 		}
			// 	});
			// }
			// // remove showPage
			// this.showPage = true;
		},
		changed1(data) {
			var oldItems = data.currentTarget.querySelectorAll("div.active");
			oldItems.forEach((e) => {
				e.classList.remove("mid");
				e.classList.remove("f");
				e.classList.remove("s");
				e.classList.remove("t");
				e.classList.remove("active");
			});
			setTimeout(() => {
				var items = data.currentTarget.querySelectorAll("div.active");
				if (items.length === 3) {
					items[2].classList.add("f");
					items[1].classList.add("mid");
					items[1].classList.add("s");
					items[0].classList.add("t");
				}
			}, 1);
		},
		changed2(data) {
			var oldItems = data.currentTarget.querySelectorAll("div.active");
			oldItems.forEach((e) => {
				e.classList.remove("first");
				e.classList.remove("sec");
				e.classList.remove("third");
			});
			setTimeout(() => {
				var items = data.currentTarget.querySelectorAll("div.active");
				if (items.length === 5) {
					items[0].classList.add("first");
					items[1].classList.add("sec");
					items[2].classList.add("third");
					items[3].classList.add("sec");
					items[4].classList.add("first");
				} else if (items.length === 3) {
					items[0].classList.add("first");
					items[1].classList.add("sec");
					items[2].classList.add("first");
				}
			}, 1);
		},
	},

	mounted() {
		Promise.all([
			this.$store.dispatch("products/fetchAll"),
			this.$store.dispatch("products/fetchRecentProducts"),
			this.$store.dispatch("silderImages/fetchAll"),
			this.$store.dispatch("offers/fetchAll"),
		])
			.then(() => {
				this.loading = false;
			})
			.finally(() => {
				this.showPage = true;
				$(document).ready(function () {
					$("#owl-demo").owlCarousel({
						navigation: false,
						slideSpeed: 300,
						paginationSpeed: 400,
						singleItem: true,
						autoPlay: true,
						autoHeight: false,
						rewindNav: true,
						rewindSpeed: 1000,
					});
				});
				document.querySelector(".carousel-item").classList.add("active");
				// this.setSlickCarousels();
				// $(document).ready(function () {
				// 	$(".owl-carousel-offer").owlCarousel({
				// 		navigation: false,
				// 		slideSpeed: 30,
				// 		paginationSpeed: 400,
				// 		autoPlay: true,
				// 		items: 3,
				// 		loop: true,
				// 		pagination: false,
				// 		afterAction: function (elem) {
				// 			this.$owlItems.removeClass("active");
				// 			this.$owlItems.removeClass("mid");
				// 			this.$owlItems.eq(this.currentItem + 1)[0].classList.add("mid");
				// 			this.$owlItems
				// 				.eq(this.currentItem + 0)[0]
				// 				.classList.add("active");
				// 			this.$owlItems
				// 				.eq(this.currentItem + 2)[0]
				// 				.classList.add("active");
				// 		},
				// 	});
				// });
				// $(document).ready(function () {
				// 	$(".owl-carousel-product").owlCarousel({
				// 		navigation: false,
				// 		slideSpeed: 30,
				// 		paginationSpeed: 400,
				// 		autoPlay: true,
				// 		pagination: false,
				// 		margin: 10,
				// 		loop: true,
				// 		responsiveClass: true,
				// 		items: 3,
				// 		// responsive: {
				// 		// 	0: {
				// 		// 		items: 3,
				// 		// 	},
				// 		// 	1200: {
				// 		// 		items: 5,
				// 		// 	},
				// 		// },
				// 		afterAction: function (elem) {
				// 			this.$owlItems.removeClass("first");
				// 			this.$owlItems.removeClass("sec");
				// 			this.$owlItems.removeClass("third");
				// 			this.$owlItems.eq(this.currentItem + 0)[0].classList.add("first");
				// 			this.$owlItems.eq(this.currentItem + 1)[0].classList.add("sec");
				// 			this.$owlItems.eq(this.currentItem + 2)[0].classList.add("third");
				// 			this.$owlItems.eq(this.currentItem + 3)[0].classList.add("sec");
				// 			this.$owlItems.eq(this.currentItem + 4)[0].classList.add("first");
				// 		},
				// 	});
				// });
			});
	},

	components: {
		Loader,
		Snackbar,
		ProductCard,
		ProductCardSmall,
		carousel,
	},
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.no-items {
	text-align: center;
	font-size: 2rem;
	font-weight: 400;
	margin: 10px auto;
}

#header {
	height: 900px;
	width: 100%;
	position: relative;
	@media (min-width: 1400px) {
		height: 1000px;
	}
	@media (max-width: 992px) {
		height: 700px;
	}
	@media (max-width: 768px) {
		height: 500px;
	}
	.hero-section-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		.hero-title-container {
			@media (min-width: 992px) {
				transform: translateY(-100px);
			}
		}
	}
	#owl-demo {
		.item {
			height: 100%;
			img {
				height: 100%;
			}
		}
	}
}
.section:not(:first-of-type) {
	background: $color-primary;
}
.section-offers {
	position: relative;
	margin-top: -200px;
	padding-top: 200px;
	@media (max-width: 992px) {
		margin-top: 0;
		padding-top: 0;
	}
	@media (min-width: 1400px) {
		margin-top: -260px;
		padding-top: 260px;
	}
	@media (min-width: 2000px) {
		padding-top: 330px;
		margin-top: -330px;
	}
	.star-bg {
		position: absolute;
		top: min(350px, calc(100vw * 0.22 * -1));
		// top: calc(100vw * 0.22 * -1);
		@media (max-width: 992px) {
			top: calc(100vw * 0.36 * -1);
		}
		right: 0;
		left: 0%;
		z-index: 0;
		img {
			width: 100%;
		}
	}
	&-outter {
		background: $color-primary;
		&-logo {
			width: 400px;
			position: relative;
			z-index: 2;
			@media (max-width: 992px) {
				padding: 0 80px;
			}
			@media (max-width: 576px) {
				width: 90vw;
			}
			img {
				width: 100%;
				z-index: 1;
			}
		}
		&-slider {
			padding: 0 100px;
			height: 1050px;
			@media (max-width: 992px) {
				height: auto;
				padding: 0 50px;
			}
			&-container {
				margin: -400px auto 0;
				@media (max-width: 992px) {
					margin: 50px auto 0;
				}
			}
		}
	}
}
.section-brands {
	padding: 50px 0;
	@media (max-width: 992px) {
		padding: 50px 10%;
	}
	&-title {
		color: white;
		font-size: 3rem;
		@media (max-width: 992px) {
			font-size: 2.5rem;
		}
	}
	&-image {
		&-item {
			img {
				width: 100%;
				transition: 200ms;
				&:hover {
					transform: scale(1.05);
				}
			}
		}
	}
}
.section-products {
	padding: 50px 150px;
	@media (max-width: 992px) {
		padding: 50px;
	}
	@media (max-width: 576px) {
		padding: 20px;
	}
	&-slider {
	}
}
.super-image {
	object-fit: cover;
	height: 100%;
	@media (max-width: 992px) {
		height: 700px;
	}
	@media (max-width: 768px) {
		height: 500px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
<style lang="scss">
.owl-carousel-offer {
	.owl-nav {
		display: flex !important;
	}
}
.owl-carousel-offer {
	.owl-stage-outer {
		padding-bottom: 420px;
		@media (max-width: 992px) {
			padding-bottom: 0;
		}
	}
	.owl-nav {
		display: flex;
		position: absolute;
		right: -30px;
		left: -30px;
		top: 50%;
		@media (max-width: 992px) {
			top: 30%;
		}
		display: flex;
		justify-content: space-between;
		.owl-prev,
		.owl-next {
			background: transparent !important;
			font-size: 1.8rem;
			border-radius: 50%;
			padding: 0 10px;
		}
	}
	// .owl-item.upset {
	// 	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	// }

	@media (min-width: 992px) {
		.owl-item {
			opacity: 0;
			position: relative;
			top: 0;
			// transition-delay: 500ms;
			// transition: opacity 200ms;
		}
		.owl-item.active {
			// transition-property: opacity;
			// transition-delay: 0.25s;
			top: 200px;
		}
		.owl-item.f {
			transition-property: opacity;
			transition-delay: 0.05s;
			transition-duration: 0.75s;
			opacity: 1;
		}
		.owl-item.s {
			transition-property: opacity;
			transition-delay: 0.15s;
			transition-duration: 0.75s;
			opacity: 1;
		}
		.owl-item.t {
			transition-property: opacity;
			transition-delay: 0.25s;
			transition-duration: 0.75s;
			opacity: 1;
		}
		.owl-item.mid {
			top: 400px;
			transform: scale(1.05);
		}
	}
}
.owl-carousel-product {
	.owl-nav {
		position: absolute;
		right: -30px;
		left: -30px;
		top: 50%;
		display: flex;
		justify-content: space-between;
		.owl-prev,
		.owl-next {
			background: transparent !important;
			font-size: 1.8rem;
			border-radius: 50%;
			padding: 0 10px;
		}
	}
	.owl-stage-outer {
		padding: 100px 0;
	}

	.owl-item {
		padding: 0 50px;
		transform: scale(0.8);
		transition: 300ms;
		@media (max-width: 992px) {
			padding: 0 10px;
		}
	}
	.owl-item.first {
		transform: scale(1.1);
	}
	.owl-item.sec {
		transform: scale(1.35);
	}
	.owl-item.third {
		transform: scale(1.5);
	}
}
.owl-stage {
	transition: 0.25s !important;
}
</style>