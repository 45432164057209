<template>
	<div>
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.cart") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.cart") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->
		<loader v-if="loading" class="mt-3" />
		<!-- Second Section Of Page -->
		<section
			class="section shared-section-container contact-section"
			id="cart-section"
			v-else
		>
			<div class="my-0">
				<!-- Stepper -->
				<div class="row d-flex justify-content-center px-5">
					<div class="col-lg-6">
						<div class="c-stepper">
							<router-link :to="{ name: 'cart' }" class="c-stepper__item">
								<h3 class="c-stepper__title stepper-active">
									<i class="fas fa-shopping-cart"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.cart") }}</p>
							</router-link>
							<div class="c-stepper__item">
								<h3 class="c-stepper__title stepper-active">
									<i class="fas fa-shipping-fast"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.shipping") }}</p>
							</div>
							<div class="c-stepper__item" @click="blockRoute">
								<h3 class="c-stepper__title">
									<i class="fas fa-credit-card"></i>
								</h3>
								<p class="c-stepper__desc">{{ $t("header.payment") }}</p>
							</div>
							<!-- Other steps -->
						</div>
					</div>
				</div>
				<!-- Stepper -->

				<div class="row d-flex justify-content-center px-5">
					<div class="col-lg-7 px-4">
						<div class="row">
							<div
								class="
									header-payment
									d-flex
									flex-sm-row flex-column
									justify-content-between
									align-items-center
									mb-4
								"
							>
								<p class="blue-text bold text-center m-0">
									{{ $t("cart.shipping.fill-data") }}
								</p>
								<p class="total-price bold text-center m-0">
									{{ $t("cart.payment.total") }}:
									{{
										Intl.NumberFormat().format(
											cart.total + shippingCost - couponValue
										)
									}}
									{{ $t("syp") }}
								</p>
							</div>
						</div>
						<div>
							<div class="row lung-align">
								<div class="col-lg-6 col-md-6 col-sm-12">
									<div class="information-item">
										<div class="information-item-label">
											{{ $t("cart.shipping.fullname") }}
										</div>
										<div class="information-item-value">
											{{ user.fullname }}
										</div>
									</div>
									<div class="information-item">
										<div class="information-item-label">
											{{ $t("cart.shipping.email") }}
										</div>
										<div class="information-item-value">
											{{ user.email }}
										</div>
									</div>
									<div class="information-item">
										<div class="information-item-label">
											{{ $t("cart.shipping.phone-number") }}
										</div>
										<div class="information-item-value">
											{{ user.phone }}
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 col-sm-12">
									<div class="information-item">
										<div class="information-item-label">
											{{ $t("cart.shipping.city") }}
										</div>
										<div class="information-item-value">
											{{ $route.query.city }}
										</div>
									</div>
									<div class="information-item">
										<div class="information-item-label">
											{{ $t("cart.shipping.address") }}
										</div>
										<div class="information-item-value">
											{{ $route.query.location }}
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div
									class="
										col-lg-12 col-md-12 col-sm-12
										row
										justify-content-center
									"
								>
									<router-link
										:to="{ name: 'cart' }"
										class="
											btn
											next-btn
											blue-bg
											d-flex
											justify-content-center
											align-items-center
											m-2
										"
										>{{ $t("buttons.back") }}</router-link
									>
									<button
										class="
											btn
											next-btn
											blue-bg
											d-flex
											justify-content-center
											align-items-center
											m-2
										"
										:class="{ disabled: loading || isActiveLoader }"
										@click="payment"
									>
										<span v-if="!loading && !isActiveLoader">
											{{ $t("buttons.place-order") }}
										</span>

										<!-- loader for until place order request end -->
										<div
											v-if="loading"
											class="spinner-border spinner-border-sm text-light"
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>

										<!-- loader for wait until go to payment page  -->
										<loader light small v-if="isActiveLoader" class="my-1" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
		<snackbar v-model="snackbar">{{ message }}</snackbar>
	</div>
</template>

<script>
import Snackbar from "../../components/Snackbar";
import Loader from "../../components/Loader";
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,
			snackbar: false,
			message: null,
			isNotValid: false,

			isActiveLoader: false,

			fullname: null,
			phone: null,
			email: null,
			address: null,
			residency: null,
			postalCode: null,
			couponValue: 0,
		};
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
			cart: (state) => state.cart.cart,
			shippingCost: (state) => state.settings.shippingCost,
		}),
	},
	methods: {
		blockRoute() {
			if (!this.$refs.form.checkValidity()) {
				this.isNotValid = true;
			}
			this.message = this.$t("component.snackbar.block-route.shipping");
			this.snackbar = true;
		},

		payment() {
			if (this.$route.query.coupon) {
				this.$router.push({
					name: "payment",
					query: {
						city: this.$route.query.city,
						location: this.$route.query.location,
						coupon: this.$route.query.coupon,
					},
				});
			} else {
				this.$router.push({
					name: "payment",
					query: {
						city: this.$route.query.city,
						location: this.$route.query.location,
					},
				});
			}
		},
	},
	components: {
		Snackbar,
		Loader,
	},
	mounted() {
		if (this.$route.query.coupon) {
			this.$store
				.dispatch("coupons/checkCoupon", { code: this.$route.query.coupon })
				.then((res) => {
					if (!res.response) {
						alert(res.message);
						this.$router.push({ name: "cart" });
					} else {
						this.couponValue = Number(res.message);
						this.loading = false;
					}
				})
				.catch(() => {
					alert(this.$t("component.snackbar.cart.invalid-coupon"));
					this.$router.push({ name: "cart" });
				});
		}
		this.loading = false;
		// this.$store.dispatch("cart/getCart");
	},
};
</script>

<style scoped lang="scss">
/* #header .hero-section-overlay {
	width: 100%;
	height: 80%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	background: rgb(18, 40, 74);
	background: linear-gradient(
		180deg,
		rgba(18, 40, 74, 0.7) 30%,
		rgba(255, 255, 255, 0) 130%
	);
} */

@import "../../sass/variables";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("/assets/cart-bg.jpg");
}

.hero-title-container {
	z-index: 1;
}

#cart-section {
	font-size: clamp(18px, 4vw, 22px);
}
.c-stepper {
	display: flex;
	margin-bottom: 1.1rem;
	padding-left: 0;
}
.c-stepper__item {
	display: flex;
	flex-direction: column;
	flex: 1;
	text-align: center;
	cursor: pointer;
}
[dir="ltr"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
[dir="rtl"] .c-stepper__item:not(:last-child):after {
	content: "";
	position: relative;
	top: calc(var(--circle-size) / 2);
	width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
	right: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
	height: 2px;
	background-color: #e0e0e0;
	order: -1;
}
.c-stepper__item:first-child::after {
	background-color: rgba(18, 40, 74, 0.7);
}
.c-stepper__title {
	font-weight: bold;
	font-size: clamp(2.2rem, 4vw, 1.25rem);
	margin-bottom: 0.5rem;
	color: #ccc;
}

@media only screen and (max-width: 500px) {
	.c-stepper__title {
		font-size: 1.3rem !important;
	}
	.c-stepper__desc {
		font-size: 0.9rem !important;
	}
}

@media only screen and (max-width: 400px) {
	.c-stepper__desc {
		font-size: 0.7rem !important;
	}
}

.stepper-active {
	color: #183b63 !important;
}
.c-stepper__desc {
	color: grey;
	font-size: clamp(0.85rem, 2vw, 1rem);
	padding-left: var(--spacing);
	padding-right: var(--spacing);
	font-size: 1.3rem;
	font-weight: 500;
	margin: 0;
}

.cart-form input,
.cart-form select {
	border: none;
	border-bottom: 2px solid #cccccc;
	background: none;
	color: #333333;
	font-size: 0.65em;
	margin-bottom: 25px;
	height: 45px;
	width: 100%;
}

::placeholder {
	color: #cccccc;
	opacity: 1; /* Firefox */
}

#city option,
#location option {
	color: #333333;
}
#city option[value=""],
#location option[value=""] {
	color: #cccccc;
}
#city:invalid,
#location:invalid {
	color: #cccccc;
}

.next-btn {
	width: 200px;
	border-radius: 50px;
	color: #fff;
	font-size: 1em;
}

.information {
	&-item {
		margin-bottom: 0.8rem;
		border-bottom: 1px solid #6d768b;
		&-label {
			font-size: 0.8rem;
			color: grey;
		}
		&-label::after {
			content: ":";
		}
		&-value {
			font-weight: bold;
			overflow: auto;
		}
	}
}
</style>
<style lang="scss">
[dir="rtl"] {
	.lung-align {
		text-align: right;
	}
}
[dir="ltr"] {
	.lung-align {
		text-align: left;
	}
}
</style>