import * as types from '../mutations-types'

export default {
    namespaced: true,

    state:{
        categories: []
    },

    actions:{
        fetchAll({ commit }){
            return window.axios.get('/categories')
            .then((response) => {
                commit(types.STORE_GATEGORIES, response.data)
                return response.data
            })
        }
    },

    mutations:{
        [types.STORE_GATEGORIES](state, categories){
            state.categories = categories;
        }
    },

    getters:{
        getCategoryById: state => id => state.categories.find(c => c.id === id)
    }
}