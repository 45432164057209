import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        branches: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/settings/allbranch/info').then(response => {
                commit(types.STORE_BRANCHES, response.data)
                return response
            })
        }
    },

    mutations: {
        [types.STORE_BRANCHES](state, branches) {
            state.branches = branches
        }
    }
}