<template>
    <div class="loader">
        <div
          class="spinner-grow"
          :class="{
            'text-dark': !isValid(light),
            'text-light': isValid(light),
            'spinner-grow-small' : isValid(small)
          }"
          v-for="n in 3"
          :key="n"
          role="status"
        >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        light: { default: null },
        small: { default: null },
    },

    methods:{
        isValid(prop){
            return prop || typeof (prop) === 'string';
        }
    }
}
</script>

<style>
.loader{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.spinner-grow{
    width: 1.1rem;
    height: 1.1rem;
    margin: 0 1rem;
    animation-direction: alternate;
}
.spinner-grow-small{
    width: .9rem;
    height: .9rem;
}
.spinner-grow:nth-of-type(1){
    animation-delay: .15s;
}
.spinner-grow:nth-of-type(2){
    animation-delay: .25s;
}
.spinner-grow:nth-of-type(3){
    animation-delay: .35s;
}
</style>