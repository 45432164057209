<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.contact-us") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.contact-us") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<!-- Second Section Of Page -->
		<section
			class="section shared-section-container contact-section"
			id="demos"
		>
			<div class="my-container my-0">
				<div class="row d-flex justify-content-center px-5">
					<div class="col-lg-7 col-md-8 col-sm-12">
						<div class="contact-title">
							<ul class="nav nav-tabs products" id="myTab" role="tablist">
								<li role="presentation">
									<button
										class="
											nav-link
											active
											microgramma-font
											large-title
											product-details
										"
										type="button"
									>
										{{ $t("contact-us.send-request") }}
									</button>
								</li>
							</ul>
						</div>
						<div class="contact-form">
							<form @submit.prevent="submit" class="text-start">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm 12">
										<input
											v-model="feedback.email"
											id="email"
											:placeholder="$t('contact-us.email')"
											type="text"
											class="fomr-control"
											required
										/>
										<select
											v-model="feedback.type"
											name="messageType"
											id="messageType"
											required
										>
											<option value="" disabled selected hidden>
												{{ $t("contact-us.message-type") }}
											</option>
											<option value="1">
												{{ $t("contact-us.type.inquiring") }}
											</option>
											<option value="1">
												{{ $t("contact-us.type.suggestion") }}
											</option>
											<option value="1">
												{{ $t("contact-us.type.complain") }}
											</option>
										</select>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12">
										<textarea
											v-model="feedback.message"
											name="message"
											id="message"
											cols="30"
											rows="10"
											:placeholder="$t('contact-us.message')"
											required
										></textarea>
									</div>
									<div class="state-messages">
										<div v-if="sendError" class="error-message">
											{{ stateMessage }}
										</div>
										<div v-if="success" class="success-message">
											{{ stateMessage }}
										</div>
									</div>
								</div>
								<div class="row">
									<button
										type="submit"
										class="button contact-btn"
										:class="{ disabled: loading }"
									>
										<span v-if="!loading">
											{{ $t("buttons.send") }}
										</span>
										<div
											v-else
											class="spinner-border spinner-border-sm text-light"
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>
									</button>
								</div>
							</form>
						</div>
					</div>

					<div class="col-lg-5 col-md-4 col-sm-12 text-start">
						<div class="contact-title width-90 d-flex">
							<ul class="nav nav-tabs products" id="myTab" role="tablist">
								<li role="presentation">
									<button
										class="
											nav-link
											active
											microgramma-font
											large-title
											product-details
										"
										type="button"
									>
										{{ $t("contact-us.contact-info.title") }}
									</button>
								</li>
							</ul>
						</div>
						<div class="contact-info">
							<ul class="info-list">
								<li class="info-item">
									<div class="title">
										<span class="title-icon"
											><i class="fas fa-phone-alt"></i
										></span>
										<span class="title-text mx-2">{{
											$t("contact-us.contact-info.phone")
										}}</span>
									</div>
									<div class="content">
										<!-- <span
											v-for="(phone, i) in contact.find(
												(e) => e.key === 'phone'
											)?.value"
											:key="i"
											>{{ phone }}
										</span> -->
										{{
											branches.find(
												(e) => e.branches.name.en === "Main Information"
											)?.phone
										}}
									</div>
								</li>
								<li class="info-item">
									<div class="title">
										<span class="title-icon"><i class="fas fa-clock"></i></span>
										<span class="title-text mx-2">{{
											$t("contact-us.contact-info.work-hours")
										}}</span>
									</div>
									<div class="content">
										<span>{{ $t("contact-us.time") }}</span>
									</div>
								</li>
								<li class="info-item">
									<div class="title">
										<span class="title-icon"
											><i class="fas fa-map-marker-alt"></i
										></span>
										<span class="title-text mx-2">{{
											$t("contact-us.contact-info.address")
										}}</span>
									</div>
									<div class="content">
										<!-- <span
											v-for="(add, i) in contact.find(
												(e) => e.key === 'address'
											)?.value"
											:key="i"
											>{{ add }}
										</span> -->
										<span>
											{{
												isEnglish
													? branches.find(
															(e) => e.branches.name.en === "Main Information"
													  )?.address.en
													: branches.find(
															(e) => e.branches.name.en === "Main Information"
													  )?.address.ar
											}}
										</span>
									</div>
								</li>
								<li class="info-item">
									<div class="title">
										<span class="title-icon"
											><i class="fas fa-map-marked-alt"></i
										></span>
										<span class="title-text mx-2">
											{{ $t("contact-us.contact-info.branches.title") }}
										</span>
									</div>
									<div class="content">
										<span>
											<div class="tabs">
												<div
													class="tab"
													v-for="branch in branches.filter(
														(e) => e.branches.name.en !== 'Main Information'
													)"
													:key="branch.id"
												>
													<input type="checkbox" :id="`chck${branch.id}`" />
													<label class="tab-label" :for="`chck${branch.id}`">
														{{
															isEnglish
																? branch.branches.name.en
																: branch.branches.name.ar
														}}
													</label>
													<div class="tab-content">
														{{ branch.phone }}<br />
														{{
															isEnglish ? branch.address.en : branch.address.ar
														}}
													</div>
												</div>
											</div>
										</span>
									</div>
									<hr class="final-line" />
								</li>
							</ul>

							<ul
								class="
									social-list
									d-flex
									justify-content-center justify-content-md-start
								"
							>
								<li class="icon brand-icon">
									<a :href="contact.find((e) => e.key === 'facebook')?.value">
										<i class="fab fa-facebook-f"></i>
										<!-- <img height="25px" src="assets/icons/facebook.png" alt=""> -->
									</a>
								</li>
								<li class="icon brand-icon">
									<a :href="contact.find((e) => e.key === 'instagram')?.value">
										<i class="fab fa-instagram"></i>
										<!-- <img height="25px" src="assets/icons/instagram.png" alt=""> -->
									</a>
								</li>
								<!-- <li class="icon brand-icon">
									<a :href="contact.find((e) => e.key === 'twitter')?.value">
										<i class="fab fa-twitter"></i>
									</a>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			// helpers
			loading: false,
			sendError: false,
			success: false,
			stateMessage: null,

			feedback: {
				email: "",
				type: "",
				message: "",
			},
		};
	},
	computed: {
		isEnglish() {
			return Boolean(!(document.querySelector("html").lang === "ar"));
		},
		...mapState({
			contact: (state) => state.settings.settings,
			branches: (state) => state.branches.branches,
		}),
	},
	methods: {
		submit() {
			this.loading = true;
			this.$store
				.dispatch("feedback/send", {
					name: "x",
					email: this.feedback.email,
					type: this.feedback.type,
					body: this.feedback.message,
				})
				.then(() => {
					this.feedback.email = "";
					this.feedback.type = "";
					this.feedback.message = "";
					this.success = true;
					this.sendError = false;
					this.stateMessage = this.$t("contact-us.sent");
				})
				.catch((e) => {
					this.sendError = true;
					this.stateMessage = this.$t("contact-us.not-sent");
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.$store.dispatch("branches/fetchAll");
	},
};
</script>

<style lang="scss" scoped>
@import "../../sass/variables";
// #header .hero-section-overlay {
// 	width: 100%;
// 	height: 80%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	z-index: 0;
// 	background: rgb(18, 40, 74);
// 	background: linear-gradient(
// 		180deg,
// 		rgba(18, 40, 74, 0.7) 30%,
// 		rgba(255, 255, 255, 0) 130%
// 	);
// }

// .hero-container {
// 	position: relative;
// 	width: 100%;
// 	height: 100vh;
// 	background: url(/assets/contact-us-bg.jpg);
// 	background-position: bottom;
// 	background-repeat: no-repeat;
// 	background-size: cover;
// }
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("/assets/contact-us-bg.jpg");
}

.hero-title-container {
	z-index: 1;
}
.arrow {
	border: solid black;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}
.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.contact-form input,
.contact-form select,
.contact-form textarea {
	border: none;
	border-bottom: 2px solid #cccccc;
	background: none;
	width: 35%;
	transition: 0.2s;
	color: #333333;
	font-size: 1rem;
	margin-bottom: 25px;
	height: 45px;
	margin-right: 2em;
	margin-top: 2.6em;
}

@media only screen and (max-width: 500px) {
	.contact-form input,
	.contact-form select,
	.contact-form textarea {
		font-size: inherit !important;
	}
}

::placeholder {
	color: #cccccc;
	opacity: 1; /* Firefox */
}

#messageType option {
	color: #333333;
}
#messageType option[value=""] {
	color: #cccccc;
}
#messageType:invalid {
	color: #cccccc;
}

.contact-form textarea {
	width: 75%;
	height: 8em;
	resize: none;
}

.contact-title {
	border-bottom: 2px solid #ccc;
	width: 65%;
	text-align: left;
}

.width-90 {
	width: 90% !important;
}

.contact-title h1 {
	border-bottom: 2px solid red;
	padding: 18px 0;
	margin: 0;
	width: max-content;
	font-size: 0.7em;
}

.contact-btn {
	width: 20%;
	background-color: #183b63;
	color: #fff;
	border-radius: 30px;
	border: none;
	font-size: 1rem;
	padding: 0.5em 1em;
	margin-left: 1.6em;
	font-weight: bold;
}

ul {
	list-style: none;
	padding: 0;
}

.info-list .info-item .title span {
	color: #183b63;
}

li.info-item {
	margin-top: 1em;
}

.info-item .title {
	font-size: 1.1rem;
}

.title-text {
	font-weight: 500;
	margin-left: 1rem;
}

.content {
	font-size: 0.8em;
	font-weight: 500;
	margin-left: 1rem;
	& span:not(:last-child)::after {
		content: ",";
	}
}

/* Accordion styles */
.tabs .tab > input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

.tab {
	width: 100%;
	overflow: hidden;
	font-size: 0.8em;
	font-weight: 500;
}
.tab-label {
	display: flex;
	justify-content: space-between;
	padding: 0.2em 0.38em;
	cursor: pointer;
	font-size: 1.2em;
	font-weight: 500;
	color: #6d768b !important;
}

/* Icon */
.tab-label::after {
	content: "❯";
	transform: rotate(90deg);
	width: 1em;
	height: 1em;
	text-align: center;
	transition: all 0.35s;
}
.tab-content {
	max-height: 0;
	padding: 0 1em;
	transition: all 0.35s;
}
.tab-close {
	display: flex;
	justify-content: flex-end;
	padding: 1em;
	font-size: 0.75em;
	background: #2c3e50;
	cursor: pointer;
}

input:checked + .tab-label::after {
	transform: rotate(270deg);
}
input:checked ~ .tab-content {
	max-height: 100vh;
	padding: 1em;
}

.final-line {
	border: 1px solid #ccc;
	width: 60%;
}

.social-list > .brand-icon {
	background-color: #183b63 !important;
	border-radius: 50% !important;
	padding: 0;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.social-list > .brand-icon > a > i {
	color: white !important;
	font-size: clamp(1em, 5vw, 0.9rem);
	/* font-size: clamp(1em, 5vw, 2rem); */
}

@media only screen and (max-width: 768px) {
	.contact-section {
		font-size: 4vw !important;
	}

	.contact-title {
		width: 100% !important;
	}

	#messageType:invalid {
		color: #cccccc;
	}
	.contact-form input,
	.contact-form select,
	.contact-form textarea {
		width: 100%;
		margin-right: 0;
	}
	.contact-form textarea {
		width: 100%;
	}
	.contact-btn {
		margin-left: auto;
		margin-right: auto;
	}
}

[dir="rtl"] .contact-form {
	input,
	select,
	textarea {
		margin-right: 0;
		margin-left: 2em;
	}
}

.state-messages {
	margin-bottom: 1rem;
	font-size: 0.8rem;
}
.error-message {
	color: #fb0010;
}
.success-message {
	color: #183b63;
}
</style>
<style>
label.is-invalid {
	display: none !important;
}
.is-invalid {
	border-bottom: 2px solid #fb0010 !important;
}
</style>