<template>
	<div>
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<!-- Overlay For Hero Section -->
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.orders") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.orders") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>

		<loader v-if="loading" />
		<section v-else class="my-container mb-5">
			<div v-if="orders.length > 0" class="">
				<div class="filter">
					<div class="filter-item">
						<label for="status">{{ $t("orders.select-status") }}</label>
						<select id="status" v-model="status">
							<option value="all" selected>
								{{ this.$t("orders.all") }}
							</option>
							<option :value="this.$t('orders.status.start')">
								{{ this.$t("orders.status.start") }}
							</option>
							<option :value="this.$t('orders.status.in-preparation')">
								{{ this.$t("orders.status.in-preparation") }}
							</option>
							<option :value="this.$t('orders.status.charged')">
								{{ this.$t("orders.status.charged") }}
							</option>
							<option :value="this.$t('orders.status.delivery')">
								{{ this.$t("orders.status.delivery") }}
							</option>
							<option :value="this.$t('orders.status.cancelled')">
								{{ this.$t("orders.status.cancelled") }}
							</option>
							<option :value="this.$t('orders.status.paide-later')">
								{{ this.$t("orders.status.paide-later") }}
							</option>
						</select>
					</div>
					<div class="filter-item">
						<label for="paid">{{ $t("orders.select-paid") }}</label>
						<select id="paid" v-model="paid">
							<option value="all" selected>
								{{ this.$t("orders.all") }}
							</option>
							<option value="1">
								{{ this.$t("orders.yes") }}
							</option>
							<option value="0">
								{{ this.$t("orders.no") }}
							</option>
						</select>
					</div>
				</div>
				<div class="d-flex my-table">
					<span class="table-header">{{
						$t("component.order-card.order")
					}}</span>
					<div class="table-header">
						{{ $t("component.order-card.total") }}
					</div>
					<div class="table-header">
						{{ $t("component.order-card.products-num") }}
					</div>
					<div class="table-header">{{ $t("component.order-card.date") }}</div>
					<div class="table-header">{{ $t("component.order-card.paid") }}</div>
					<div class="table-header">
						{{ $t("component.order-card.status") }}
					</div>
				</div>
				<order-card
					v-for="(order, i) in finallyOrdrs"
					:key="order.id"
					:id="order.id"
					:to="{ name: 'view-order', params: { id: order.id } }"
					:total="order.total_after_shipping"
					:productsNum="order.cart.products.length"
					:date="order.created_at"
					:paid="order.paid"
					:status="paidType[order.status]"
					:blue="i % 2 === 1 ? true : false"
					:cancelled="order.status === 4"
				/>
			</div>

			<div
				v-else
				class="
					text-center
					mx-auto
					border-2
					rounded-3
					py-2
					border-top border-bottom
					w-25
				"
			>
				{{ $t("no-data.no-orders") }}
			</div>
		</section>
	</div>
</template>

<script>
import Loader from "../../components/Loader";
import OrderCard from "../../components/OrderCard";
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,

			paidType: {
				0: this.$t("orders.status.start"),
				1: this.$t("orders.status.in-preparation"),
				2: this.$t("orders.status.charged"),
				3: this.$t("orders.status.delivery"),
				4: this.$t("orders.status.cancelled"),
				5: this.$t("orders.status.waitting"),
				6: this.$t("orders.status.paide-later"),
			},

			status: "all",
			paid: "all",
		};
	},
	methods: {
		fetchOrders() {
			this.$store.dispatch("orders/fetchAll").then(() => {
				this.loading = false;
			});
		},
		async fetch() {
			if (this.authenticated) {
				await this.$store.dispatch("orders/fetchAll").finally(async () => {
					for (let i = 0; i < this.orders.length; i++) {
						const order = this.orders[i];
						const orderDate = window.moment(order.created_at);
						if (order.status === 6) {
							if (orderDate.add(24, "h").isBefore(window.moment())) {
								await this.$store.dispatch("orders/cancel", { id: order.id });
							}
						}
					}
				});
				this.fetchOrders();
			}
		},
	},
	computed: {
		reverseOrders() {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			return this.orders.reverse();
		},
		key() {
			return Object.keys(this.paidType).find(
				(key) => this.paidType[key] === this.status
			);
		},
		finallyOrdrs() {
			return this.reverseOrders.filter(
				(e) =>
					(this.status === "all" ||
						e.status ===
							Number(
								Object.keys(this.paidType).find(
									(key) => this.paidType[key] === this.status
								)
							)) &&
					(this.paid === "all" || e.paid === Number(this.paid))
			);
		},
		...mapState({
			authenticated: (state) => state.auth.authenticated,
			orders: (state) => state.orders.orders,
		}),
	},

	watch: {
		authenticated() {
			this.fetch();
		},
	},

	mounted() {
		if ("auth" in localStorage) {
			this.fetch();
		} else {
			this.$router.push({ name: "login" });
		}
	},

	components: {
		Loader,
		OrderCard,
	},
};
</script>

<style lang="scss" scoped>
$order-card-width: 16rem;
.my-table {
	border-bottom: 2px solid #173b63;
	@media (max-width: 992px) {
		display: none !important;
	}
}
.table-header {
	width: calc(100% / 6);
	text-align: center;
	font-size: 1.5rem;
	font-weight: bold;
	padding: 10px 0;
}
// #header .hero-section-overlay {
//     width: 100%;
//     height: 80%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 0;
//     background: rgb(18, 40, 74);
//     background: linear-gradient(
//         180deg,
//         rgba(18, 40, 74, 0.7) 30%,
//         rgba(255, 255, 255, 0) 130%
//     );
// }

.filter {
	display: flex;
	margin: 1rem 0;
	&-item {
		margin-inline-end: 20px;
		font-size: 1rem;
		label {
			margin-inline-end: 10px;
		}
		select {
			border: 1px solid #ccc;
			border-radius: 10px;
			background: transparent;
		}
	}
}
.hero-container {
	position: relative;
	width: 100%;
	height: 100vh;
	background: url(/assets/cart-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.hero-title-container {
	z-index: 1;
}

.order-card {
	// min-width: $order-card-width;
}

.grid-orders {
	display: grid;
	grid-template-rows: min-content;
	grid-template-columns: repeat(auto-fit, $order-card-width);

	grid-auto-rows: min-content;
	grid-auto-columns: repeat(auto-fit, 280px);

	row-gap: 30px;
	column-gap: 30px;

	justify-content: center;
}
</style>