<template>
    <router-link class="article-card" :to="to">
        <div class="article-card-image" :style="`background-image: url(${article.image})`" alt="article-image"></div>
        <div class="article-card-date"><i class="far fa-calendar-alt me-2"></i>{{moment(article.updated_at)}}</div>
        <h2 class="article-card-title">
            {{article.title}}
        </h2>
    </router-link>
</template>

<script>
export default {
    props:{
        article: { default: null },
        to: { required: true }
    },

    methods:{
        moment(date){
            return window.moment(date).format('DD-MM-YYYY');
        }
    }
}
</script>

<style lang="scss">
.article-card{
    &-image {
        min-width: 100%;
        min-height: 210px;
        background-position: center;
        background-size: cover;
        border-radius: 20px;
    }
    &-date {
        margin: 6px auto;
        color: #a7a7a7;
        text-align: center;
        font-size: .9rem;
    }
    &-title {
        text-align: center;
        font-size: 1rem;
        font-weight: 300;
        margin: 0;

        &, &:link, &:visited, &:hover{
            color: #6d768b;
        }
    }
}
</style>