<template>
	<div>
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<!-- Overlay For Hero Section -->
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.view-order") }} {{ order.id ? `#${order.id}` : "" }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						>
						<router-link :to="{ name: 'orders' }" class="text-white">{{
							$t("header.orders")
						}}</router-link>
						>
						<span class="route-page"
							>{{ $t("header.order") }}
							{{ order.id ? `#${order.id}` : "" }}</span
						>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<loader v-if="loading" />
		<section v-else class="my-container mb-5">
			<h2 class="view-order-title">
				{{ $t("orders.order-items") }}
				{{ order.id ? `#${order.id}` : "" }}
			</h2>
			<div class="main" v-if="order.cart.products.length > 0">
				<div class="grid-items">
					<item-card
						v-for="item in order.cart.products"
						:key="item.id"
						:to="{ name: 'view-product', params: { id: item.product.id } }"
						:mainImage="item.product.main_image"
						:catalogName="item.product.catalog_name"
						:ItemName="item.product.name"
						:tag="item.product.tag"
						:quantity="item.quantity"
						:total="item.total"
					/>
				</div>
				<div class="buttons d-flex justify-content-center mt-5">
					<div
						class="btn next-btn blue-bg mx-2"
						@click="changePaymentType()"
						v-if="!order.payment_type && order.status !== 4"
					>
						{{ $t("orders.payment-type") }}
					</div>
					<div
						class="btn next-btn blue-bg mx-2"
						@click="addSameOrder()"
						style="min-width: 180px"
					>
						<div
							v-if="addLoading"
							class="spinner-border spinner-border-sm text-light"
							role="status"
						>
							<span class="sr-only">Loading...</span>
						</div>
						<span v-else>
							{{ $t("orders.add-same") }}
						</span>
					</div>
				</div>
			</div>
			<div
				v-else
				class="
					text-center
					mx-auto
					border-2
					rounded-3
					py-2
					border-top border-bottom
					w-25
				"
			>
				{{ $t("no-data.no-cart-items") }}
			</div>
		</section>
	</div>
</template>

<script>
import ItemCard from "../../components/ItemCard";
import Loader from "../../components/Loader";
import { mapState } from "vuex";
import { isOffer } from "../../fun";
export default {
	data() {
		return {
			loading: true,
			addLoading: false,

			order: {},
		};
	},

	computed: {
		...mapState({
			authenticated: (state) => state.auth.authenticated,
			cart: (state) => state.cart.cart,
			offers: (state) => state.offers.offers,
		}),
	},
	methods: {
		isOffer,
		isProductInCart(productId) {
			return this.cart.products.find((c) => c.product.id === productId);
		},
		addSameOrder() {
			if (this.addLoading) return;
			this.addLoading = true;
			const promises = [];
			for (let i = 0; i < this.order.cart.products.length; i++) {
				const orderProduct = this.order.cart.products[i];
				const cartProduct = this.isProductInCart(orderProduct.product.id);
				if (cartProduct) {
					if (orderProduct.product.active) {
						promises.push(
							this.$store.dispatch("cart/editProduct", {
								productId: cartProduct.product.id,
								item_id: cartProduct.id,
								quantity:
									orderProduct.quantity +
									this.cart.products.find(
										(c) => c.product.id === orderProduct.product.id
									).quantity,
								price:
									this.isOffer(orderProduct.product, this.offers)?.new_price ||
									orderProduct.product.price,
							})
						);
					}
				} else {
					if (orderProduct.product.active) {
						promises.push(
							this.$store.dispatch("cart/addProduct", {
								item_id: orderProduct.product.id,
								quantity: orderProduct.quantity,
								price:
									this.isOffer(orderProduct.product, this.offers)?.new_price ||
									orderProduct.product.price,
							})
						);
					}
				}
			}
			Promise.all(promises).finally(() => {
				this.$store.dispatch("cart/getCart").then(() => {
					this.addLoading = false;
					this.$router.push({ name: "cart" });
				});
			});
		},
		changePaymentType() {
			this.$router.push({
				name: "payment",
				query: { editPaymentType: this.order.id },
			});
		},
	},
	watch: {
		authenticated() {
			if (this.authenticated) {
				Promise.all([
					this.$store
						.dispatch("orders/fetchById", { id: this.$route.params.id })
						.then((data) => {
							this.order = data;
						}),
					this.$store.dispatch("offers/fetchAll"),
				]).finally(() => {
					this.loading = false;
				});
			}
		},
	},

	mounted() {
		if ("auth" in localStorage) {
			if (this.authenticated) {
				Promise.all([
					this.$store
						.dispatch("orders/fetchById", { id: this.$route.params.id })
						.then((data) => {
							this.order = data;
						}),
					this.$store.dispatch("offers/fetchAll"),
				]).finally(() => {
					this.loading = false;
				});
			}
		} else {
			this.$router.push({ name: "login" });
		}
	},

	components: {
		ItemCard,
		Loader,
	},
};
</script>

<style lang="scss" scoped>
$item-card-width: 18.8rem;

.next-btn {
	width: 200px;
	border-radius: 50px;
	color: #fff;
	font-size: 1rem;
	width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
}

// #header .hero-section-overlay {
//     width: 100%;
//     height: 80%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 0;
//     background: rgb(18, 40, 74);
//     background: linear-gradient(
//         180deg,
//         rgba(18, 40, 74, 0.7) 30%,
//         rgba(255, 255, 255, 0) 130%
//     );
// }

.hero-container {
	position: relative;
	width: 100%;
	height: 100vh;
	background: url(/assets/cart-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.hero-title-container {
	z-index: 1;
}

.item-card {
	min-width: $item-card-width;
}

.grid-items {
	display: grid;
	grid-template-rows: min-content;
	grid-template-columns: repeat(auto-fit, $item-card-width);

	grid-auto-rows: min-content;
	grid-auto-columns: repeat(auto-fit, 280px);

	row-gap: 30px;
	column-gap: 30px;

	justify-content: center;
	align-items: center;
}

.view-order-title {
	color: #13274a;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 1.5rem;
}
</style>