import { render, staticRenderFns } from "./ProductCardSmall.vue?vue&type=template&id=5c0f1350&scoped=true&"
import script from "./ProductCardSmall.vue?vue&type=script&lang=js&"
export * from "./ProductCardSmall.vue?vue&type=script&lang=js&"
import style0 from "./ProductCardSmall.vue?vue&type=style&index=0&id=5c0f1350&prod&scoped=true&lang=scss&"
import style1 from "./ProductCardSmall.vue?vue&type=style&index=1&id=5c0f1350&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0f1350",
  null
  
)

export default component.exports