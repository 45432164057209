import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products'
import categories from './modules/categories'
import news from './modules/news'
import feedback from './modules/feedback'
import auth from './modules/auth'
import cart from './modules/cart'
import orders from './modules/orders'
import silderImages from './modules/slider-images'
import payment from './modules/payment'
import settings from './modules/settings'
import offers from './modules/offers'
import branches from './modules/branches'
import coupons from './modules/coupons'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		products,
		categories,
		news,
		feedback,
		auth,
		cart,
		orders,
		silderImages,
		payment,
		settings,
		offers,
		branches,
		coupons
	}
})
