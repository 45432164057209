import moment from 'moment';
import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        offers: [],
        recentProducts: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/offers')
                .then((response) => {
                    commit(types.STORE_OFFERS, response.data)
                    return response.data
                })
        },

        fetchById({ commit }, { productId }) {
            return window.axios.get(`/offers/${productId}`)
                .then((response) => {
                    return response.data
                })
        },

        fetchRecentProducts({ commit }) {
            return window.axios.get('/offers')
                .then((response) => {
                    commit(types.STORE_RECENT_OFFERS, response.data)
                    return response.data
                })
        },

        search({ commit }, { name }) {
            return window.axios.get('/offers/search', {
                params: { name }
            })
                .then((response) => {
                    return response.data;
                })
        }

    },

    mutations: {
        [types.STORE_OFFERS](state, offers) {
            var now = moment().utc("DD-MM-YYYY")
            state.offers = offers.map(e => {
                e.start_Date = moment.utc(e.start_Date, "DD-MM-YYYY")
                e.end_Date = moment.utc(e.end_Date, "DD-MM-YYYY")
                e.active = now.isAfter(e.start_Date) && !now.isAfter(e.end_Date)
                return e
            })
        },

        [types.STORE_RECENT_OFFERS](state, recentProducts) {
            state.recentProducts = recentProducts
        },
    },

    getters: {
        getProductById: state => id => state.offers.find(c => c.id === id)
    }
}