import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang/i18n'
import axios from 'axios'
import API_URL from './constants'
import PaceProgress from "pace-progress";
import moment from 'moment'
import './helpers'
import * as types from './store/mutations-types'
import JQuery from 'jquery'

window.$ = JQuery

window.moment = moment;
window.axios = axios.create({
	baseURL: API_URL,
});
window.axios.defaults.headers.common['accept-language'] = i18n.locale; // changing language for API requests

Vue.config.productionTip = false

if ('auth' in localStorage) {
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		window.axios.defaults.headers.common.Authorization = 'Bearer ' + auth.token;

		store.dispatch('auth/getself')
			.then(() => {
				store.commit('auth/' + types.LOGIN);
				store.dispatch('cart/getCart')
			})
			.catch(() => {
				store.dispatch('auth/logout');
			});
	}
	catch {
		localStorage.removeItem('auth');
	}
}
else {
	store.dispatch('cart/getCart').then((res) => {
		store.dispatch('cart/setId', { id: res.id })
	})
}

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')

PaceProgress.start();