<template>
	<div>
		<loader v-if="loading" style="height: 100vh" />
		<template v-else>
			<navbar v-if="$route.name !== 'page-404'"></navbar>
			<router-view></router-view>
			<v-footer v-if="$route.name !== 'page-404'"></v-footer>
		</template>
	</div>
</template>

<script>
import Loader from "./components/Loader";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
export default {
	data() {
		return {
			loading: false,
		};
	},

	components: {
		Navbar,
		"v-footer": Footer,
		Loader,
	},

	watch: {
		"$i18n.locale"() {
			window.axios.defaults.headers.common["accept-language"] =
				this.$i18n.locale;
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 1500);
		},
	},
};
</script>

<style lang="scss">
@import "./sass/_variables.scss";
@import "./sass/_utilities.scss";
@import "./sass/_base.scss";
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: white;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 3px;
}

.pace .pace-progress-inner {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #fff, 0 0 5px #112647;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-moz-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	-o-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
	display: block;
	position: fixed;
	z-index: 2000;
	top: 15px;
	right: 15px;
	width: 14px;
	height: 14px;
	border: solid 2px transparent;
	border-top-color: #112647;
	border-left-color: #112647;
	border-radius: 10px;
	-webkit-animation: pace-spinner 400ms linear infinite;
	-moz-animation: pace-spinner 400ms linear infinite;
	-ms-animation: pace-spinner 400ms linear infinite;
	-o-animation: pace-spinner 400ms linear infinite;
	animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes pace-spinner {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes pace-spinner {
	0% {
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-ms-keyframes pace-spinner {
	0% {
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes pace-spinner {
	0% {
		transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
///////////////////////////////////
.shared-wave-shape {
	fill: #fbfbfb;
}

body {
	font-size: 1.4rem;
}
:root {
	// variables for stepper in card, shipping and payment pages
	--circle-size: clamp(1.5rem, 5vw, 3rem);
	--spacing: clamp(0.25rem, 2vw, 0.5rem);
}

input,
select,
textarea {
	&:focus,
	&:focus-visible {
		outline: none;
		transition: border-bottom-color 0.5s;
		border-bottom-color: darken(#112647, 10%) !important;
	}

	&.is-not-valid:invalid {
		border-bottom-color: #e62c2c !important;
		animation: invalid-shake 0.3s ease 0.4s forwards;

		@keyframes invalid-shake {
			0% {
				transform: translateX(0px);
			}
			25% {
				transform: translateX(-6px);
			}
			50% {
				transform: translateX(6px);
			}
			75% {
				transform: translateX(-6px);
			}
			100% {
				transform: translateX(0px);
			}
		}
	}
}
#-error.is-invalid {
	display: none !important;
}

button.disabled {
	background-color: rgba(#112647, 0.6);
	pointer-events: none;
}

.product-btn {
	background-color: $color-primary;
}

#header.inner-header {
	&,
	.hero-container,
	.hero-container .hero-section-overlay {
		height: 60vh;
		@media (max-width: 500px) {
			height: 50vh;
		}
	}
}

.spinner-border-sm {
	width: 1.4rem;
	height: 1.4rem;
}

[dir="rtl"] *:not([class*="fa-"]) {
	font-family: "Tajawal", sans-serif;
}

// [dir="rtl"] .custom-shape-divider-bottom-1638581975 {
// 	// transform: scaleX(-1);
// }
///////////////////////////////////

.cls-1,
.cls-11 {
	transform-origin: center;
	transform: rotateY(180deg);
}
.shared-wave-shape {
	transform-origin: center;
	transform: rotateY(180deg);
}

@media (max-width: 576px) {
	.nav-tabs {
		padding-right: 0;
	}
}
.nav-tabs .nav-link {
	margin-bottom: -2px;
	border: 2px solid transparent;
	@media (max-width: 576px) {
		padding: 1.5rem 0.5rem;
	}
}
.nav-tabs .nav-link.active {
	font-weight: bold;
}

.fix-text-align {
	[dir="rtl"] & {
		text-align: right;
	}
	[dir="ltr"] & {
		text-align: left;
	}
}
.fix-text-align-mirr {
	[dir="rtl"] & {
		text-align: left;
	}
	[dir="ltr"] & {
		text-align: right;
	}
}
.fix-position {
	[dir="rtl"] & {
		right: 0;
	}
	[dir="ltr"] & {
		left: 0;
	}
}
.fix-position-mirr {
	[dir="rtl"] & {
		left: 0;
	}
	[dir="ltr"] & {
		right: 0;
	}
}

.my-container {
	padding: 0 50px;
	@media (max-width: 1200px) {
		padding: 0 30px;
	}
	@media (max-width: 992px) {
		padding: 0 15px;
	}
}
.hero-page-route {
	text-align: center;
	a,
	span {
		width: max-content;
		display: inline-block;
	}
}
</style>
