<template>
	<router-link :to="to" class="item-card">
		<div class="item-card-img">
			<img :src="mainImage" alt="product" />
		</div>

		<div class="item-card-description">
			<div class="item-card-category">{{ catalogName }}</div>
			<div class="item-card-name">{{ ItemName }}</div>
			<div class="item-card-code">
				{{ $t("component.item-card.product-code") }}: {{ tag }}
			</div>
		</div>

		<div class="order-card-label">
			{{ $t("component.item-card.quantity") }}:
			<span class="order-card-value">{{ quantity }}</span>
		</div>
		<div class="order-card-label">
			{{ $t("component.item-card.total-price") }}:
			<span class="order-card-value"
				>{{ Intl.NumberFormat().format(total) }} {{ $t("syp") }}</span
			>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		to: { default: {} },
		mainImage: { default: null },
		catalogName: { default: null },
		ItemName: { default: null },
		tag: { default: null },
		quantity: { default: null },
		total: { default: null },
	},
};
</script>

<style lang="scss">
@import "../sass/_variables.scss";

.item-card {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 16rem;
	min-height: 14rem;
	border-radius: 30px;
	padding: 1rem 1.5rem;
	border: 1px solid darken($color-light, 10%);
	&,
	&:link,
	&:visited,
	&:hover {
		color: inherit;
	}

	&-img {
		height: 120px;
		margin-bottom: 0.8rem;
		> img {
			height: 100%;
			object-fit: contain;
		}
	}

	&-value {
		color: $color-primary;
	}

	&-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 0.5rem;
		font-size: clamp(1.4em, 4vw, 50%);
	}

	&-category,
	&-name,
	&-code {
		text-align: center;
	}

	&-category {
		font-size: 0.55em;
		font-weight: 500;
		color: #13274a;
	}

	&-name {
		font-size: 0.7em;
		font-weight: 600;
		color: #333;
	}

	&-code {
		color: #ccc;
		font-size: 0.48em;
		font-weight: 400;
	}

	&-label,
	&-value {
		text-align: center;
	}

	&-label {
		text-transform: capitalize;
		font-size: 1.2rem;
	}
}
</style>