import * as types from '../mutations-types'

export default {
    namespaced: true,
    state: {
        silderImages: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/slider-images')
                .then((response) => {
                    commit(types.STORE_SLIDER_IMAGES, response.data);
                    return response.data;
                })
        }
    },

    mutations: {
        [types.STORE_SLIDER_IMAGES](state, silderImages) {
            state.silderImages = silderImages;
        }
    }
}