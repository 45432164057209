<template>
	<router-link :to="to" class="order-card" :class="{ blue: blue }">
		<div class="order-card-label">
			<span>{{ $t("component.order-card.order") }}</span>
			<span class="order-card-value">#{{ id }}</span>
		</div>
		<div class="order-card-label">
			<span>{{ $t("component.order-card.total") }}: </span>
			<span class="order-card-value"
				>{{ Intl.NumberFormat().format(total) }}{{ $t("syp") }}</span
			>
		</div>
		<div class="order-card-label">
			<span>{{ $t("component.order-card.products-num") }}: </span>
			<span class="order-card-value">{{ productsNum }}</span>
		</div>
		<div class="order-card-label">
			<span>{{ $t("component.order-card.date") }}: </span>
			<span class="order-card-value">{{ moment(date) }}</span>
		</div>
		<div class="order-card-label">
			<span>{{ $t("component.order-card.paid") }}: </span>
			<span class="order-card-value">
				{{
					paid
						? $t("component.order-card.paid-state.yes")
						: $t("component.order-card.paid-state.no")
				}}
			</span>
		</div>
		<div class="order-card-label">
			<span>{{ $t("component.order-card.status") }}: </span>
			<span class="order-card-value" :class="{ red: cancelled }">
				{{ status }}
			</span>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		to: { default: {} },
		total: { default: null },
		productsNum: { default: null },
		date: { default: null },
		paid: { default: null },
		id: { default: null },
		status: { default: null },
		blue: { default: false },
		cancelled: { default: false },
	},

	methods: {
		moment(date) {
			return window.moment(date).format("YYYY-MM-DD");
		},
	},
};
</script>

<style lang='scss' scoped>
@import "../sass/_variables.scss";
.order-card {
	width: 100%;
	display: inline-flex;
	align-items: center;
	padding: 1rem 0;
	border-radius: 50px;
	transition: 100ms;
	@media (max-width: 992px) {
		flex-wrap: wrap;
	}
	&,
	&:link,
	&:visited,
	&:hover {
		color: inherit;
	}

	&-title,
	&-label,
	&-value {
		text-align: center;
		width: calc(100% / 6);
		font-size: 1.3rem;
		padding: 2px 0;
		@media (max-width: 992px) {
			width: calc(100% / 3);
		}
		@media (max-width: 768px) {
			width: calc(100% / 2);
		}
	}

	&-title {
		color: lighten($color-primary, 10%);
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: 400;
	}

	&-label {
		text-transform: capitalize;
		font-size: 1.2rem;
	}

	&-value {
		color: $color-primary;
	}
	&:hover {
		background: #ccc;
	}
}
.blue {
	background: #173b63;
	* {
		color: white;
	}
	&:hover {
		background: #112845;
	}
}
.red {
	color: red !important;
	font-weight: 500;
}
@media (min-width: 992px) {
	.order-card {
		span:first-child {
			display: none;
		}
	}
}
@media (max-width: 992px) {
	.order-card-label {
		text-align: start;
		padding-inline-start: 15px;
	}
}
@media (max-width: 576px) {
	.order-card * {
		font-size: 1rem;
	}
}
</style>