import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        products: [],
        recentProducts: [],
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/products')
                .then((response) => {
                    commit(types.STORE_PRODUCTS, response.data)
                    return response.data
                })
        },

        fetchByCategory({ commit }, { categoryId }) {
            return window.axios.get(`/categories/${categoryId}/products`)
                .then((response) => {
                    commit(types.STORE_PRODUCTS, response.data)
                    return response.data
                })
        },

        fetchById({ commit }, { productId }) {
            return window.axios.get(`/products/${productId}`)
                .then((response) => {
                    return response.data
                })
        },

        fetchRecentProducts({ commit }) {
            return window.axios.get('/products')
                .then((response) => {
                    commit(types.STORE_RECENT_PRODUCTS, response.data)
                    return response.data
                })
        },

        search({ commit }, { name }) {
            return window.axios.get('/products/search', {
                params: { name }
            })
                .then((response) => {
                    return response.data;
                })
        }

    },

    mutations: {
        [types.STORE_PRODUCTS](state, products) {
            state.products = products
        },

        [types.STORE_RECENT_PRODUCTS](state, recentProducts) {
            state.recentProducts = recentProducts
        },
    },

    getters: {
        getProductById: state => id => state.products.find(c => c.id === id)
    }
}