<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.articles") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.articles") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<loader v-if="loading"></loader>
		<div class="my-container pt-4 pb-5" v-else>
			<div v-if="news.length > 0" class="grid-article">
				<article-card
					v-for="newsIndex in news"
					:key="newsIndex.id"
					:to="{ name: 'view-article', params: { id: newsIndex.id } }"
					:article="newsIndex"
				/>
			</div>
			<div
				v-else
				class="
					text-center
					mx-auto
					border-2
					rounded-3
					py-2
					border-top border-bottom
					w-25
				"
			>
				{{ $t("no-data.no-articles") }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ArticleCard from "../../components/ArticleCard";
import Loader from "../../components/Loader";
export default {
	data() {
		return {
			loading: true,
		};
	},

	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},

	mounted() {
		this.$store.dispatch("news/fetchAll").finally(() => {
			this.loading = false;
		});
	},

	components: {
		ArticleCard,
		Loader,
	},
};
</script>

<style lang="scss" scoped>
@import "../../sass/_variables.scss";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("../../../public/assets/article.jpg");
}
.grid-article {
	display: grid;
	grid-template-rows: min-content;
	grid-template-columns: repeat(auto-fit, 280px);

	grid-auto-rows: min-content;
	grid-auto-columns: repeat(auto-fit, 280px);

	row-gap: 30px;
	column-gap: 30px;

	justify-content: center;
	align-items: center;
}
</style>