var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-card my-product-card"},[_c('router-link',{staticClass:"d-block",attrs:{"to":{ name: 'view-product', params: { id: _vm.product.product_details.id } }}},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.product.product_details.main_image,"alt":""}})])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.isEnglish? _vm.product.product_details.name : _vm.product.product_details.name)+" ")]),_c('div',{staticClass:"text-description"},[_vm._v(" "+_vm._s(_vm.isEnglish? _vm.product.notes :_vm.product.notes)+" ")]),_c('div',{staticClass:"text-type"},[_vm._v(_vm._s(_vm.isEnglish? _vm.product.product_details.manufacturer : _vm.product.product_details.manufacturer))])]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"price-old"},[_vm._v(" "+_vm._s(Intl.NumberFormat().format(_vm.product.product_details.price))+_vm._s(_vm.$t("syp"))+" ")]),_c('div',{staticClass:"price-new"},[_vm._v(" "+_vm._s(Intl.NumberFormat().format(_vm.product.new_price))+_vm._s(_vm.$t("syp"))+" ")])]),_c('button',{staticClass:"product-btn mt-2 text-white",class:{
			disabled:
				_vm.productLoading ||
				_vm.isProductInCart(_vm.product.product_details.id) ||
				!_vm.product.active,
		},on:{"click":function($event){return _vm.addProduct(_vm.product.product_details.id)}}},[(
				_vm.productLoading && _vm.selectedProductId === _vm.product.product_details.id
			)?_c('div',{staticClass:"spinner-border spinner-border-sm text-light",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('a',{attrs:{"type":"button"}},[(!_vm.product.active)?[_vm._v(" "+_vm._s(_vm.$t("buttons.out-of-stock"))+" ")]:(!_vm.isProductInCart(_vm.product.product_details.id))?[_vm._v(" "+_vm._s(_vm.$t("buttons.add"))+" "),_c('i',{staticClass:"fas spin fa-shopping-cart"})]:[_vm._v(" "+_vm._s(_vm.$t("buttons.added"))+" "),_c('i',{staticClass:"fas spin fa-check"})]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }