<template>
    <div
      class="snackbar"
      :class="{'sanckbar-active': snackbar}"
      v-show="snackbar"
      :style="{'opacity': opacity}"
    >
        <div class="d-flex align-items-center justify-content-between">
            <p class="my-2 flex-grow-1 text-center"><slot></slot></p>
            <div
              @click="$emit('change-snackbar', false)"
              class="d-flex justify-content-center align-items-center px-3"
            >
                <i class="close-icon fas fa-times"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[
        'snackbar'
    ],

    model:{
        prop: 'snackbar',
        event: 'change-snackbar'
    },

    data(){
        return {
            opacity: 1,
            timeoutId: null
        }
    },

    watch:{
        snackbar(){
            if (this.snackbar){
                this.timeoutId = setTimeout(() => {
                    this.opacity = 0;
                    setTimeout(() => {
                        this.opacity = 1;
                        this.$emit('change-snackbar', false)
                    }, 500); 
                }, 6000);
            }
            else {
                clearTimeout(this.timeoutId);
            }
        }
    },
}
</script>

<style lang="scss">
@import '../sass/variables';
$animation-duration: .6s;

@mixin snackbar-width($mx){
    max-width: $mx;
    left: calc((100% - #{$mx}) / 2);
    right: calc((100% - #{$mx}) / 2);
}

.snackbar{
    position: fixed;
    bottom: -10%;
    z-index: 99999;

    transition: opacity .3s;

    padding: 3px 1.6rem;
    background-color: #112647;
    color: $color-light;
    border: 1px solid $color-light;
    border-radius: 10px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    
    font-size: .9rem;
    text-align: center;

    @include snackbar-width(90%);

    @media screen and (min-width: $sm) {
        @include snackbar-width(80%)
    }

    @media screen and (min-width: $md) {
        @include snackbar-width(50%)
    }

    .close-icon{
        font-size: 1.3rem;
        cursor: pointer;
    }

    [dir="rtl"] &{
        font-weight: bold;
    }
}

.sanckbar-active{
    animation: snackbar-in $animation-duration cubic-bezier(.34,.34,.42,1.33) forwards;
    @keyframes snackbar-in {
        from{
            bottom: -10%;
        }
        to{
            bottom: 20px;
        }
    }
}
</style>