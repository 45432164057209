<template>
	<div>
		<!-- First Section Of Page -->
		<header id="header" class="header inner-header section text-contrast cover">
			<!-- Hero Image -->
			<div
				class="
					hero-container
					d-flex
					flex-column
					justify-content-center
					align-items-center
				"
			>
				<div
					class="
						hero-title-container
						d-flex
						flex-column
						justify-content-center
						align-items-center
					"
				>
					<h1 class="microgramma-font red-text hero-page-title">
						{{ $t("header.products") }}
					</h1>
					<h5 class="hero-page-route">
						<router-link :to="{ name: 'home' }" class="text-white">{{
							$t("header.home")
						}}</router-link>
						> <span class="route-page">{{ $t("header.products") }}</span>
					</h5>
				</div>
			</div>
			<!-- Hero Image -->

			<!-- Curve Wave Shape Bottom Page -->
			<div class="custom-shape-divider-bottom-1638581975">
				<svg
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1920 334"
					preserveAspectRatio="none"
				>
					<path
						class="shared-wave-shape"
						d="M1897,35.59l0,0c-.49.44-1,.87-1.5,1.3-10.54,9-24.27,17.2-41.27,24.4-13.33,7-29.42,12.06-46.41,16.46-10.15,2.63-20.61,5-31,7.42l-6.57,1.53c-14,3.43-28.62,6.21-43.64,8.44-57.14,8.13-115.08,14.66-173.38,20.46-89,8.86-178.78,16-267.8,24.57C1069.61,160.34,851.63,177,636,198.42c-151,15-300.88,32.27-448,54.67l-7.89,1.44c-12,2.19-24.45,4.43-36.92,6.83-28.64,5.51-57.54,11.83-82.71,20.15C40.2,288.21,22.36,296.21,9,306.12c-.35.26-.72.51-1.06.77A19.12,19.12,0,0,0,2.06,315C-.27,321.14,1,327.75,0,334H1920V0a59.29,59.29,0,0,1-3.16,9.24C1912.78,18.61,1906.18,27.43,1897,35.59Z"
						transform="translate(0)"
					/>
				</svg>
			</div>
			<!-- Curve Wave Shape Bottom Page -->
		</header>
		<!-- First Section Of Page -->

		<loader v-if="loading"></loader>

		<!-- Second Section Of Page -->
		<section
			v-else
			class="section shared-section-container my-container"
			id="demos"
		>
			<div class="section-heading text-center row mb-3 justify-content-center">
				<h2 class="search-text" v-if="products.length > 0">Search results</h2>
				<div class="col-lg-10 mx-auto">
					<div
						class="
							tab-pane
							fade
							show
							active
							d-flex
							flex-wrap
							justify-content-center
							products-collection
						"
					>
						<!-- Start Of Slider Item -->
						<div
							class="
								col-md-3 col-sm-6 col-xs-12
								justify-content-between
								item item-product
							"
							v-for="product in products"
							:key="product.id"
						>
							<router-link
								:to="{ name: 'view-product', params: { id: product.id } }"
							>
								<img
									class="product-img"
									:src="product.main_image"
									alt="product"
								/>
								<h2 class="product-name black-text">{{ product.name }}</h2>
								<h2 class="product-price blue-text">
									{{ Intl.NumberFormat().format(product.price) }} SYP
								</h2>
							</router-link>
							<h2 class="product-rate mb-1">
								<i class="fa fa-star checked"></i>
								<i class="fa fa-star checked"></i>
								<i class="fa fa-star checked"></i>
								<i class="fa fa-star checked"></i>
								<i class="fa fa-star checked"></i>
							</h2>

							<button
								@click="addProduct(product)"
								class="product-btn mt-2 text-white"
								:class="{
									disabled:
										productLoading ||
										isProductInCart(product.id) ||
										!product.active,
								}"
							>
								<div
									v-if="productLoading && selectedProductId === product.id"
									class="spinner-border spinner-border-sm text-light"
									role="status"
								>
									<span class="sr-only">Loading...</span>
								</div>
								<a type="button" v-else>
									<template v-if="!product.active">
										{{ $t("buttons.out-of-stock") }}
									</template>
									<template v-else-if="!isProductInCart(product.id)">
										{{ $t("buttons.add") }}
										<i class="fas spin fa-shopping-cart"></i>
									</template>
									<template v-else>
										{{ $t("buttons.added") }}
										<i class="fas spin fa-check"></i>
									</template>
								</a>
							</button>
						</div>
						<!-- End Of Slider Item -->

						<div
							v-if="products.length === 0"
							class="
								text-center
								mx-auto
								border-2
								rounded-3
								py-2
								border-top border-bottom
								w-25
							"
						>
							NO RESULTS
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Second Section Of Page -->
	</div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "../../components/Loader";
export default {
	data() {
		return {
			loading: true,
			productLoading: false,
			selectedProductId: null,

			products: [],
		};
	},

	methods: {
		addProduct(product) {
			this.productLoading = true;
			this.selectedProductId = product.id;
			if (this.authenticated) {
				this.$store
					.dispatch("cart/addProduct", {
						item_id: product.id,
						quantity: 1,
						price: product.price,
					})
					.then(() => {
						this.$store.dispatch("cart/getCart").finally(() => {
							this.productLoading = false;
							this.selectedProductId = null;
						});
					})
					.catch((e) => {
						this.message = e.response.data.error;
						this.snackbar = true;
					});
			} else {
				// this.$store
				// 	.dispatch("cart/addProduct", {
				// 		item_id: product.id,
				// 		quantity: 1,
				// 		price: product.price,
				// 		id: this.cartId,
				// 	})
				// 	.then(() => {
				// 		this.$store
				// 			.dispatch("cart/getCartById", { id: this.cartId })
				// 			.finally(() => {
				// 				this.productLoading = false;
				// 				this.selectedProductId = null;
				// 			});
				// 	})
				// 	.catch((e) => {
				// 		this.message = e.response.data.error;
				// 		this.snackbar = true;
				// 	});
				this.$store.dispatch("cart/addToLocalCart", {
					id: product.id,
					quantity: 1,
					price: product.price,
				});
				setTimeout(() => {
					this.productLoading = false;
					this.selectedProductId = null;
				}, 500);
			}
		},

		isProductInCart(productId) {
			return Boolean(
				this.cart.products.find((c) => c.product.id === productId) ||
					this.localCart.find((c) => c.id === productId)
			);
		},

		fetchSeachProducts() {
			this.loading = true;
			this.$store
				.dispatch("products/search", {
					name: this.$route.query.search,
				})
				.then((data) => {
					this.products = data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	computed: {
		...mapState({
			authenticated: (state) => state.auth.authenticated,
			cart: (state) => state.cart.cart,
			localCart: (state) => state.cart.localCart,
		}),
	},

	watch: {
		$route() {
			this.fetchSeachProducts();
		},
	},

	mounted() {
		this.fetchSeachProducts();
	},

	components: {
		Loader,
	},
};
</script>

<style lang='scss' scoped>
@import "../../sass/_variables.scss";
.hero-container {
	background-image: linear-gradient(
			to bottom,
			rgba($color-primary, 0.9),
			rgba($color-primary, 0.3)
		),
		url("../../../public/assets/products.jpg");
}
.search-text {
	color: $color-primary;
	text-align: center;
	font-size: 2.4rem;
	font-weight: bold;
}
</style>