import * as types from "../mutations-types"

export default {
    namespaced: true,

    state: {
        coupons: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/coupons').then(response => {
                commit(types.STORE_COPONS, response.data)
                return response
            })
        },
        checkCoupon({ commit }, { code }) {
            return window.axios.get(`/coupons/usercoupon`, { params: { code: code } }).then(response => {
                return response.data
            })
        }
    },
    mutations: {
        [types.STORE_COPONS](state, coupons) {
            state.coupons = coupons
        }
    }
}